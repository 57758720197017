html,body{
  width: 100vw;
  overflow-x: hidden;
}
@font-face {
    font-family: proxima-regular;
    src: url("../../fonts/ProximaNova-Regular.ttf");
  }
  
  @font-face {
    font-family: proxima-bold;
    src: url("../../fonts/ProximaNova-Bold.ttf");
  }
  @font-face {
    font-family:circular-regular;
    src: url("../../fonts/Circular_Std_Book.ttf");
  }
  html{
    scroll-behavior: smooth;
  }
  
  #website_container{  
  
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
     font-family: unset  !important;
    color: #0B0B0B;
  }
  
   a{
    font-family: proxima-regular;
  }
   p,
  span {
    font-family: proxima-regular;
    color: #0B0B0B;
  }
}
 #home,#courses{
    section {
   opacity: 0;
   transition: all 1s;
 }
} 
.scroll_ani{
  opacity: 1 !important;
}

#home,#courses{
  p,span,div{
    font-size: 16px;
  }
}
#scrollTopId{
  position: fixed;
  z-index: 1000;
  right: 3%;
  bottom: 6%; 
  width: 50px;
  height: 50px;
  transform: scale(0);
  border: transparent;
  background-color: #fff;
  display: grid;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  transition: 0.5s;
  border-radius: 100%;
  border: solid 2px  #83bd1b;
  cursor: pointer;
  svg{
    transform: rotate(-90deg);
    font-weight: bold;
    path{
      fill:  #83bd1b;
      font-weight: bold;
      stroke-width: 0px;
    }
  }
  }
/* common responsive */
@media (max-width: 768px) {
  #home,#courses            {
  h1,
  h1 > span {
   font-size: 35px !important;
   text-align: center;
 }
  h2,
  h2  span {
   font-size: 25px !important;
 }
  h3 {
   font-size: 22px !important;
 }
  h4 {
   font-size: 18px !important;
 }
  p {
   font-size: 16px !important;
 }
  span {
   font-size: 16px !important; 
   line-height: 28px;
 }
}
}


@media (min-width: 1200px)
{
    #website_container{
      .container,  .container-lg,  .container-md,  .container-sm,  .container-xl {
        max-width: 1360px !important;
    }
    }
}


#home .active_btn
{
    
    color: white !important;
}
#home .inactive_btn
{
  border-color:#1878f1 !important;
  color: #1878f1 !important;
}

#home .main_title
{
 font-size: 46px ;
 font-weight: 400 !important;
 font-family: 'proxima-regular' !important;
}

#home .sub_title
{
    font-size: 24px !important;
    font-weight: 400 !important;
}
#home .description
{
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 24px !important;
}
#home .section_spaces
{
    padding:40px 0px;
}

#home .text-center {
    text-align: center !important;
    margin-top: 0px !important;
    margin-right: 0px !important;
}


#home .header_section
{
    background-color: white;
    box-shadow: 0px 0px 20px rgba(80, 80, 80, 0.25);
    z-index: 1000;
    position: sticky;
}

#home .navbar-brand img
{
    width:90%;
}
#home .nav-item {
    padding: 8px 13px !important;
}
#home .navbar-light .navbar-nav .active>.nav-link
{
    color: #0081d7 !important;
}

#home .header_section p span{
    color:#0081d7;
}

@media (max-width: 425px) 
{
    #home .navbar-brand
    {
        width:70%;
    }
    
}

@media (min-width: 768px)
{
    #home .navbar-collapse.collapse,#home .navbar-collapse.collapse {
        display: flex !important;
    }
    
}

#home a.navbar-brand {
    margin-left: 0px !important;
}

#home .navbar-light .navbar-brand
{
    padding: 15px 0px 15px 0px;
}

/* Banner CSS */

#home,#courses {
  
  .banner_sec 
{
    background-image: url('../../images/websitenew/bg_img.png');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 77vh;
    button{
      height: 45px;
      width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      path{
        fill: #fff;
      }
    }
    .apply_now_btn{
      background-color:  #83bd1b;
      color: #fff;
    }
    .download_brochure_btn{
      background-color: #FFFFFF;
      border: 1px solid #606060;
    }
    .website_left_inner_banner{
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .website_banner_standing_girl
    {
      width: 70%;
      margin-top: 40px;
      max-width: 550px;
    }
    .banner_round_icon{
      position: absolute;
      border-radius: 100%;
      height: 90px;
    }
    p {  
      font-size: 18px;
      font-weight: 400;
      span {
     color: #0081d7;
     font-size: 28px;
     font-weight: 600;
    }
    }
    h1
    {
    font-size: 48px;
    font-weight: 600;
    margin: 0;
    }
}
}
#courses{
  .banner_sec 
  {
    background-position: top right;
    min-height: 60vh;
    .website_left_inner_banner{
      min-height: 60vh;
    }
    .website_banner_standing_girl{
      width: 65%;
      max-width: 420px;
    }
    .banner_round_icon{
      border-radius: 0%;
    }
    p {  
      font-size: 18px;
      font-weight: 400;
      span {
     color: #0081d7;
     font-size: 18px;
     font-weight: 400;
    }
  }
}
}

@media (max-width: 768px) 
{
    #home .banner_sec
    {
      height: auto; 
      position: relative;
      &:after{
        content: '';
        position: absolute;
        inset: 0;
        background-image: url('../../images/websitenew/bg_img.png') !important;
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        opacity: 0.7;
      }
       >div{
        z-index: 1;
        position: relative;
        background-color: transparent;
       }
        
    }   
} 

#home .centered_content
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: inherit;
}





#home .banner_sec p
{
 font-size: 18px;
 font-weight: 400;
}

#home .counter_section
{
    background-color: white;
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; 
    font-size: 20px;
    padding: 20px;
    position: relative;
    top: -45px;
}
#home .counter_section .desk_100{
  display: block;
}
#home .counter_section .mbl_100{
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}
#home .counter_section span
{
    font-size: 44px;
    font-weight: 600;
    color:  #83bd1b;
  
}



@media (min-width: 1200px)
{
    #home .counter_area .container {
        max-width: 100% !important;
        border-radius: 0 !important;
    }
}

#home{
  .highlights_our_course{
    position: relative;
    &::after{
      content: '';
      position: absolute;
      background-image: url('../../images/websitenew/bg_img.png');
      background-size: 90%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top left;
      width: 100%;
      height: 100%;
      inset: 0;
      z-index: 0;
      opacity: 0.5;
    }
    >div{
      z-index: 1;
      position: relative;
    }
    

   
    .highlights_our_course_inner_left{
      position: relative;
      min-height: 500px;
      h4{
        font-weight: 600;
      }
      .hos_small_box{
        position: absolute;
        height: 130px;
        width: 130px;
        border-radius: 10px;
        transform:rotate(-45deg) scale(0);
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(73, 73, 73, 0.2);
        border-radius: 100%;
        >div{
            position: absolute;
            transform: rotate(45deg) translate(-15%,37%);
            max-width: 95px;
            left: 40%;
            top: 31%;
            h4{
              text-align: center;
              font-size: 27px;
              margin: 0;
            }
            p{
              text-align: center;
              margin: 0;
              
            }
            
        }
      }
      .hos_small_box.with_heading{
        >div{
          left: 33%;
          top: 44%;
        }
      }
   
      .hos_big_box{
        position: absolute;
        height: 230px;
        width: 230px;
        border-radius: 10px;
        transform:rotate(-45deg) scale(0);
        background: #FFFFFF;
        box-shadow: 0px 0px 20px rgba(73, 73, 73, 0.2);
        border-radius: 100%;
        >div{
          position: absolute;
          transform: rotate(45deg) translate(48%, 95%);
          left: 24%;
          max-width: 95px;
          h4{
            font-size: 42px;
            text-align: center;
          }
          p{
            text-align: center;
            font-size: 20px;
          }
          
      }
      }

      .hsb1{
        left: 10%;
      }
      .hsb2{
        left: 59%;
        height: 150px;
        width: 150px;
         background: #83bd1b;
      }
      .hsb3{
        left: 80%;
        top: 15%;
        background: #4fb4ca;
      }
      .hsb3 p,.hsb2 p{
        color: #fff;
      }
      .hsb4{
        left: 28%;
        top: 10%;
    
      }
      .hsb5{
        height: 200px;
        width: 200px;
        left: 57%;
        top: 38%;
      }
      .hsb6{
        background: #4fb4ca;
        left: 20%;
        top: 54%;
    }
    .hsb6 p,.hsb7 p{
      color: #fff;
    }
      .hsb7{
        background: #83bd1b;
        left: 41%;
        top: 66%;
    }
    
    
      .hsb8{
        left: 83%;
        top: 65%;
      }

      .dummy_small_box{
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 5px;
        transform:rotate(-45deg) scale(0);
      }
      .dummy_smallest_box{
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        transform:rotate(-45deg) scale(0);
      }
      .hsb9{
        left: 18%;
        top: 36%;
      }
      .hsb10{
        left: 19%;
        top: 42%;
      }
      .hsb11{
        left: 88%;
        top: 44%;
      }
    }
    .highlights_our_course_inner_right{
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 500px;
      max-width: 400px;
      margin: auto;
      h2{
        font-size: 45px
      }
      .learn_more{
        color: #1778F2;
        font-weight: 600;
        margin-top: 15px;
        cursor: pointer;
        path{
          fill: #1778F2;
        }
      }
    }
  }
  .animated_highlights_our_course{
      .hos_big_box{
        animation: HighlightZoomAni 1s 0.4s forwards;
      }
      .hos_small_box{
        animation: HighlightZoomAni 1s 1.5s forwards;
      }
      .dummy_small_box{
        animation: HighlightZoomAni 0.5s 2s forwards;
      }
      .dummy_smallest_box{
        animation: HighlightZoomAni 0.5s 2s forwards;
      }
  }
}
@keyframes HighlightZoomAni {
  from{
    transform:rotate(-45deg) scale(0);
  }
  to{
    transform:rotate(-45deg) scale(1);
  }
}

 
#home{
  .website_our_courses{
    h2{
      font-size: 45px;
      text-align: center;
      ~ p{
        max-width: 900px;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    #mbl_website_our_courses{
      display: none;
    }  
    #desk_website_our_courses{
      display: block;
    }
  .list_courses_con{
    width: 100%;
    .list_courses{
      height: 60px;
      display: grid;
      grid-template-columns: 1fr 10fr 1fr;
      border-top: solid 2px #e8e8e8;
      align-items: center;
      padding: 0 20px;
      .lc_side_number{
        height:25px;
        width:25px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:100%;
        margin-right: 5px;
        font-size:18px;
      }
      .lc_side_number::after{
        content: '.';
      }
      p{
        margin: 0;
        font-weight: 500;
        font-size:18px;
        color: #0B0B0B;
      }
      path{
        fill: #0B0B0B;
      }
     
    }
    .list_courses:last-child{
      border-bottom: solid 2px #e8e8e8;
    }
    .list_courses:hover,.list_courses_active{
      background-color: #83bd1b;
      .lc_side_number{
        border-color:#fff ;
        color: #fff;
      }
      p{
        color: #fff;
      }
      path{
        fill: #fff;
      }
    }
    
   
  
  }
  .website_courses_con{
    height: 650px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 30px;
  .website_courses{
    display: flex;
    background-color: #F8F8F8;    
    transition: all 1s !important;
    transition-delay: 0.5s !important;
    margin-top: 20px;
    min-height: 150px;
    max-height: 280px;
    

    img{
      width: 40%;
      object-fit: cover;
    }
    >div{
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      span{
        display: none;
      }
      h4{
        font-weight: bold;
      }
      div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
          font-weight: bold;
          display: flex;
          align-items: center;
        }
        a:hover{
          color: #01B170;
          path{
            fill:#01B170 ;
          }
        }
      }
    }
    h5{
      font-size: 18px;
      font-weight: bold;
    }

  }
  
}
/* width */
.website_courses_con::-webkit-scrollbar {
  width:10px;
}

/* Track */
.website_courses_con::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.website_courses_con::-webkit-scrollbar-thumb {
  background: #83bd1b; 
  border-radius: 10px;

}

/* Handle on hover */
.website_courses_con::-webkit-scrollbar-thumb:hover {
  background: rgb(73, 73, 73); 
}
}
}
.website_courses.website_courses_active{
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 40px, rgb(0 0 0 / 5%) 0px 15px 12px;
  >div{
    span{
      display: inline !important;
    }
 
  }
}


/* Methodology sec CSS */

#home .methodology_sec 
{
 img{
   margin-bottom: 15px;
 }
 svg{
  height: 50px;
  width: 50px;
  margin-bottom: 15px;
  path,circle{
    transition: all 0.5s ;
  }
  &:hover{
    path,circle{
      fill: #83bd1b !important;
    }
    
  }
 }
}

#home .methodology_sec span
{
 color: #0081d7;
 font-size: 20px;
 font-weight: 600;
}

/* projects_sec css */

#home .projects_sec img
{
    width:93%;
    border-radius: 10px;
    min-width: 250px;
    min-height: 250px;
}

#home .image_icon
{
    width: 80px !important;
    max-height: 90px;
    position: absolute;
    top: 29px;
    right: 0px;
    background-color: #fff;
    padding: 13px;
    border-radius: 100% !important;
    margin: auto;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
    svg,img{
      height: 50px;
      width: 50px;
      max-width: 90px;
      max-height: 90px;
      min-width: 10px;
      min-height: 10px;
    }
}
#home .projects_sec .sub_title,#home .projects_sec .description{
  text-align: center;
}
#home .mt-5
{
    margin-top:50px;
}


#home{
  .website_batch_detail{
    h2{
      font-size: 45px;
      text-align: center;
      ~ p{
        max-width: 900px;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    .batch_pricing_box{
      background: #FFFFFF;
      border: 1px solid  #83bd1b;
      border-radius: 15px;
      transition: all 0.3s;
          .batch_pricing_box_header{
            display: flex;
            border-bottom: 1px solid #C8C8E4;
            justify-content: center;
            padding: 10px 0;
            align-items: center;     
            position: relative; 
            h3{
              font-size: 28px;
              font-weight: 400;
              color:  #83bd1b;
            }
            svg{
              margin-right: 10px;

            }
            h6{
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -5px;
              font-weight: bold;
            }

          }
          .batch_pricing_box_pricing_con{
            margin-top: 20px;
            >li{
              padding: 16px 20px;
              display: grid;
             grid-template-columns: 1fr 5fr;
             
            
              h5{
                font-weight: normal;
                font-size: 18px;
                margin-top: 0;
                margin: 0;
              }
            }
            >li:hover{
              background-color: #fff;
            }
          }
          button{
            width: 85%;
            height: 50px;
            font-weight: 700;
            font-size: 20px;
            background: #01B170;
            border-radius: 15px;
            border: transparent;
            color: #fff;
            display: block;
            margin: 20px auto;
          }
          button:disabled{
            background: #8ee8c7;
            cursor: not-allowed;
          }
    }
    #batch_pricing_box2{
      li{
        grid-template-columns: 1fr;
        border-bottom: solid 1px #f5f5f5;
        h5{
          text-align: left;
          padding-left: 15px;
        }
      }
    }
    .batch_pricing_box_inactive{
      border: 1px solid grey;
      filter: blur(5px);
      .batch_pricing_box_header{
      h3{
        color: grey;
      }
      svg,path,rect,circle{
        fill: grey;
      }
     
    }
    li:hover{
      background-color: #fff !important;
    }
    }
  }
}




/* Q & A section CSS */
#home{
  .qa_sec{
    background-image: url('../../images/websitenew/bg_img.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left top;
  }
}

#home #faq_sec,#home #courseacc_sec{
    margin: 50px auto;
    max-width: 900px;
  }
  
  #home #faq_sec #faq .card {
    border: 0;
    border-bottom: 1px solid #D3D3D3;
  }
  #home #courseacc_sec #courseacc .card {
    border-bottom: 1px solid #D3D3D3;
  }
  
  #home #faq_sec #faq .card .card-header,  #home #courseacc_sec #courseacc .card .card-header {
    border: 0;
    -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
            box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
    border-radius: 2px;
    padding: 0;
    border-bottom: 1px solid #D3D3D3;
  }
  
  #home #faq_sec #faq .card .card-header .btn-header-link,#home #courseacc_sec #courseacc .card .card-header .btn-header-link {
    color: #fff;
    display: flow-root;
    text-align: left;
    background:  #83bd1b;
    color: rgb(255, 255, 255);
    padding: 20px;
  }
  
  #home #faq_sec #faq .card .card-header .btn-header-link:after,#home #courseacc_sec #courseacc .card .card-header .btn-header-link:after {
    content: "-";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    float: right;
  }
  
  #home #faq_sec #faq .card .card-header .btn-header-link.collapsed,#home #courseacc_sec #courseacc .card .card-header .btn-header-link.collapsed {
    background: #ffffff;
    color: #383860;
  }
  
  #home #faq_sec #faq .card .card-header .btn-header-link.collapsed:after,#home #courseacc_sec #courseacc .card .card-header .btn-header-link.collapsed:after {
    content: "+";
  }
  
  #home #faq_sec #faq .card .collapsing,#home #courseacc_sec #courseacc .card .collapsing {
    line-height: 30px;
  }
  
  #home #faq_sec #faq .card .collapse,#home #courseacc_sec #courseacc .card .collapse {
    border: 0;
  }
  
  #home #faq_sec #faq .card .collapse.show,  #home #courseacc_sec #courseacc .card .collapse.show {
    background: #F7F7F7;
    line-height: 30px;
    color: #222;
  }

  #home #faq_sec .card-body, #home #courseacc_sec .card-body
  {
    padding-left: 48px !important;
    background: #F7F7F7;
    padding: 10px 0px;
  }


  #home,#courses{
    .website_certificate{
      h2{
        font-size: 40px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 20px;
        text-align: center;
      ~ p:not(.website_certificate_left_inner >p){
        max-width: 1100px;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
      }
      }
     .row{
       align-items: center;
       display: flex;
       flex-wrap: wrap;
       justify-content: center;
     }
      .website_certificate_left_inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        h2{
          font-size: 40px;
          font-weight: 400;
          text-align: left;
        }
        p{
          max-width: 500px;
          margin-bottom: 20px;
        }
        button{
          background:  #83bd1b;
          border-radius: 5px;
          font-size: 15px;
          color: #fff;
          width: 180px;
          padding: 8px 30px;
          border: transparent;
        }
      }
      img{
        max-height: 375px;
        width: 90%;
        display: block;
        box-shadow: 0px 0px 20px #80808059;
        margin: 46px auto;
      }
    }
  }



  #home{
    .rating_feedback{
      h2{
        font-size: 45px;
        text-align: center;
        ~ p{
          max-width: 900px;
          margin: auto;
          text-align: center;
          font-size: 16px;
        }
      }
     
      .website_feedback_container{
        margin-top: 30px;
        min-height: 450px;
        .slick-slider{
          min-height: 400px;
        }
        .website_feedback{      
          min-height: 250px;
          background: #FFFFFF;
          border: 1px solid #c9eeff;
          box-sizing: border-box;
          border-radius: 25px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 20px;
          transition: all 0.5s;
          margin-bottom: 45px;
          >img{
            height: 27px;
          }
          >div{
            display: grid;
            grid-template-columns: 1fr;
            >div{
              h5{
                font-weight: 600;
                font-size: 18px;
              }
            }
          }

        }

        .website_feedback_active{
          box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
          background-color: #83bd1b;
          box-sizing: border-box;
          color: white;
          p,h5{
           color: #fff !important;
          }
        }

      }
    }


  }




  .website_footer{
    padding: 40px 0;  
    color: #fff;
    background-color: #83bd1b;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 0px;
    padding-top: 80px;
    a:hover{
     color: #fff;
    }
    svg{
      cursor: pointer !important;
      rect{
        fill: #567a15 !important;
      }
    }
  }
  .website_footer h3{
    color: #fff !important;
    font-size: 23px;
  }
  .website_footer h4{
    color: #fff !important;
    font-size: 17px;
    text-align: left !important;
  }
  .website_footer .row{
    border-bottom: solid 1px #fff;
   
    /* padding-bottom: 25px; */
  }
  .website_footer .row>div{
    margin-bottom: 20px;
  }
  .website_footer h2,.website_footer h3,.website_footer h4{
  color: #fff;
  }
  .website_footer li,.website_footer p,.website_footer a{
    color: #fff !important;
    transition: all 0.5s;
    }
    .website_footer h3{
      margin-bottom: 10px;
    }
    .website_footer a:hover{
      color: #bbe5ff !important;
    }
  .social_logo_con {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .social_logo_con > svg {
    cursor: pointer;    
  }
  .social_logo_con  svg rect {
    transition: all 0.3s;
  }
  
  /* .social_logo_con > svg:hover path {
    fill: var(--ora) !important;
    
  } */
  .social_logo_con svg rect{
    fill: #606080;
  }
  .social_logo_con a:nth-child(1) svg:hover rect{
    fill: #3b5995;
  }
  .social_logo_con a:nth-child(2) svg:hover rect{
    fill: #56aeed;
  }
  .social_logo_con a:nth-child(3) svg:hover rect{
    fill: orangered;
  }
  .social_logo_con a:nth-child(4) svg:hover rect{
    fill: #007ab9 ;
  }
  
  .social_logo_con svg:nth-child(1):hover rect{
    fill: #3b5995;
  }
  .social_logo_con svg:nth-child(2):hover rect{
    fill: #56aeed;
  }
  .social_logo_con svg:nth-child(3):hover rect{
    fill: orangered;
  }
  .social_logo_con svg:nth-child(4):hover rect{
    fill: #007ab9 ;
  }
  
  .contact_details_inner{
    display: grid;
    grid-template-columns: 1fr 10fr;
  }
  .contact_details_inner > svg path {
    transition: all 0.3s;
  }
  .contact_details_inner:hover > svg path {
    fill: #fff !important;
  }
  .footer_link_list li{
    margin: 5px;
  }
  .footer_bottom_row{
    display: grid;
    grid-template-columns: 1fr  ; 
    max-width: 900px;
    min-width: 320px;
    margin: auto;
    justify-items: center;
    margin-top: 10px;
  }
  .footer_bottom_row a:not(.footer_bottom_row a:last-child){
    border-right: 2px solid #F46E74;
    padding: 0 30px;
  }
  @media (max-width:850px) {
  .contact_details_inner p{
  max-width: 420px;
  }
  
  }
  @media (max-width:767px) {
    #home .website_our_courses .website_courses_con .website_courses{
      max-height: 520px;
    }
    #home .highlights_our_course .highlights_our_course_inner_left .hos_big_box > div{
      max-width: 500px;
    }
    /* .footer_bottom_row{
      grid-template-columns: none;
    } */
    .footer_bottom_row>a{
      margin: 5px 0;
    }
    .footer .row{
      padding-bottom: 5px;
    }
    /* .footer .row>div{
      margin-bottom: 35px;
    } */
   
  }
  @media (max-width:400px){
    .nav_sub_con{
      left: -50%;
      width: 320px;
    }
    .nav_con li a,.nav_con li div{
      font-size: 16px;
    }
    .nav_sub_link a{
      grid-template-columns:1fr 3fr;
    }
  }
  

/* HEADER */
.website_header {
  background-color: #fff;
  height: 100px; 
  /* headerHeightChanges */
  width: 100%;
  box-shadow: 0px 4px 20px rgba(81, 81, 81, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  .header_inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    }
    .header_inner img {
    display: block;
     max-width: 140px;
    /*max-height: 100px; */
    /* headerHeightChanges */
    margin-left: 4%;
    cursor: pointer;
    }
    .nav_con {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 600px;
    margin: 0;
    }
    .nav_con li{
      padding-bottom: 0px;
    
    /* headerHeightChanges */
    }
    .nav_link{
      padding-bottom: 0px !important;
      a{
        font-size: 16px !important;
      }
    }
    .nav_con li a,.nav_con li div {
    color: #000;
    font-weight: 500;
    transition: all 0.3s;
    font-size: 15px;
    font-weight: normal;
    font-family: circular-regular !important;
    }
    .nav_con li a:hover {
    color: var(--ora);
    }
    .nav_con li a:hover svg path{
    transition: fill 0.3s;
    fill: var(--ora);
    }
    .nav_sub{
    position: relative;
    }
    .nav_sub>a> img{
    position: absolute;
    width: 27px;
    top: 0;
    right: -23px;
    }
    .nav_sub_con{
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    width: 580px;
    height: 275px;
    flex-wrap: wrap;
    padding: 20px;
    padding-top: 40px;
    border-radius: 25px;
    left: -140px;
    top: 40px;
    display: none;
    }
    .nav_sub:hover .nav_sub_con{
    display: flex;
    }
    .nav_sub_link{
    width: 33.3%;
    }
    .nav_sub_link a{
    display: grid;
    grid-template-columns: 1fr 2fr;
    }
    .nav_sub_link a > img{
    width: 30px;
    }
    .active_link{
    border-bottom: solid #ED1C24;
    box-sizing: border-box;
    }
    .active_link a:not(.nav_sub_con a){
    color: #ED1C24 !important;
    }
    .active_link svg path{
    fill: #ED1C24 !important;
    }
    .nav_link a button{
    margin: -10px;
    }
    .toggle_button{
    background-color: transparent;
    color: #000;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    display: none;
    }
    #mbl_header_down_arr{
    display: none;
    }
    .knowledge_matrix_logo{
    display: block;
      max-height: 95px;
      margin-left: 4%;
      margin-top: 3px;
      cursor: pointer;
    }
    .nav_link_btn_white a button{
      background: #FFFFFF;
      border: 1px solid #CDCDCD;
      border-radius: 5px;
      width: 93px;
      height: 44px;
    }
    .nav_link_btn_green button{
      background: #0081d7;
      border: 1px solid #CDCDCD;
      color: #fff;
      border-radius: 5px;
      font-weight: 500;
      width: 120px;
      height: 44px;
    }
  }
.counter_area{
  z-index: 2;
  position: relative;
}
 /* RESPONSIVE NAVBAR */
@media (max-width:993px) {
  #home .banner_sec{
    background-position: left;
  }
  #home .banner_sec .website_left_inner_banner{
    min-height: 40vh !important;
  }
  #home .banner_sec .row{
    display: flex;
    flex-direction: column-reverse;
  }
  #home .banner_sec {
    height: auto;
    position: relative;
}
  .website_header {
  .nav_con{
  position: absolute;
  min-width: 100%!important;
  top: 100px;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  padding-top: 15px;
  left: -100%;
  transition: all 0.5s;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  justify-content: left !important;
  li{
    padding: 15px 0 !important;
    margin: 0;
    width: 100%;
    border-bottom: solid 1px #f1f1f1;
    &:nth-child(6),&:nth-child(7),&:nth-child(8){
      border-bottom: transparent;
    } 
    display: flex;
    justify-content: center;
  }
  }
  
  .toggle_button{
  display: block ;
  }
  .header_inner{
  align-items: center;
  }
  .active_link{
  border: none;
  }
  .active_link a:not(.nav_sub_con a){
  border-left: solid;
  padding-left: 10px;
  }
  
  .nav_sub_con{
  display: none;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  height: 300px;
  width: 390px;
  overflow: auto;
  }
  .nav_sub_link{
  width: 50%;
  }
  .nav_con li a{
  font-size: 18px;
  }
  .nav_con ~ .toggle_button .fa-bars.nav_fa_bars{
  display: block;
  }
  .nav_con ~ .toggle_button .fa-times.nav_fa_times{
  display: none;
  font-size: 19px;
  }
  .nav_con.nav_view ~ .toggle_button .fa-bars.nav_fa_bars{
  display: none;
  }
  .nav_con.nav_view ~ .toggle_button .fa-times.nav_fa_times{
  display: block;
  }
  #desk_header_down_arr{
  display: none;
  }
  #mbl_header_down_arr{
  display: block;
  margin-left: 15px;
  }
  .nav_sub{
  display: flex;
  align-items: center;
  }
  .header_inner >img{
    height: 180px;
    }
    .website_header{
      height: 100px;
      width: auto;
    }
    .nav_link img{
      height: 30px;
    }
  }
  }
  .nav_view{
    left:0 !important;
    }

@keyframes youllMasterAni {
  to{
    opacity: 1;
  }
}


@media (max-width: 768px) {
  #home .banner_sec .banner_round_icon{
    display: none;
  }
  #home .banner_sec .website_left_inner_banner,#courses .banner_sec .website_left_inner_banner{
    min-height: 75vh;
    align-items: center;
    justify-content: center;
    }
    #home .banner_sec img{
      margin-top: 0 !important;
    }
    #home .counter_section>div{
      width: 50%;
      margin: 10px 0;
    }
    #home .highlights_our_course .highlights_our_course_inner_left 
    {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between  ;
      >div{
      position: relative !important;
      transform: rotate(0) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      left: unset !important;
      height: 165px !important;
      width: 45% !important;
      margin-bottom: 10px;
      border-radius: 10px !important;
      div{
        position: relative !important;
        transform: rotate(0) !important;
        left: 0 !important;
      }
     }
     .dummy_smallest_box ,.dummy_small_box{
       display: none !important; 
     }
    }

    #home .highlights_our_course{
      .row{
        display: flex;
        flex-wrap: wrap-reverse;
      .col-lg-5{
        margin: 15px auto;
        .highlights_our_course_inner_right{
          min-height: 200px;
    
        }
      }
    }
    } 

    #home .tools_you_master .master_tools_con .master_tools_col:nth-child(odd){
      margin-top: 0;
    }
    #home .website_certificate .website_certificate_left_inner{
      margin: auto;
      button{
        margin: auto;
      }
    } 

    #home .website_batch_detail .batch_pricing_box{
      margin: 25px 0;
    }
    #home #faq_sec #faq .card .card-header .btn-header-link{
      white-space: pre-wrap;
    }
    .website_footer{
      background-position: bottom right;
    }
    .footer_bottom_row a:not(.footer_bottom_row a:last-child){
      padding: 0 12px;
    }
    
#home{
    .animated_highlights_our_course{
      .hos_big_box{
        animation: HighlightZoomAniRes 1s forwards;
      }
      .hos_small_box{
        animation: HighlightZoomAniRes 1s  forwards;
      }
      .dummy_small_box{
        animation: HighlightZoomAniRes 0.5s  forwards;
      }
      .dummy_smallest_box{
        animation: HighlightZoomAniRes 0.5s  forwards;
      }
  }
}
#home .website_our_courses .website_courses_con .website_courses img{
  display: none;
}
#home .website_our_courses .website_courses_con .website_courses > div{
  width: 100%;
}
#courses .banner_round_icon{
  display: none;
}
#courses .about_free_certification .about_free_certification_left_inner::after{
  display: none;
}
}
@keyframes HighlightZoomAniRes {
  from{
    transform:scale(0);
  }
  to{
    transform:scale(1);
  }
}
@media (max-width:481px) {
  #home .tools_you_master .master_tools_con .master_tools_col .master_tools{
    width: 150px;
  }
  #home .counter_section {
    padding-top: 60px;
  }
  #home .counter_section .desk_100{
    display: none;
  }
  #home .counter_section .mbl_100{
    display: block;
    font-size: 40px !important;
    top: 20px;
  }
}

.register_popup,.highlight_popup{
  height: 600px;
  width: 90%;
  max-width: 900px;
  background-color: #FFFFFF;
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  position: relative;
  overflow: hidden;

  h1{
    font-size: 30px;
    font-weight: 100;
    font-family: circular-regular;
    text-align: center;
  }
.register_popup_close{
  position: absolute;
  right: 10px;
  top: 10px;
  circle{
    fill: #fff;
  }
  path{
    fill: #000;
  }
}
.register_popup_close:hover{
  circle{
    fill:  #83bd1b;
  }
  path{
    fill: #fff;
  }
}

.register_popup_inner_left{
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #F5F5F5;
  padding:20px;
  img{
    max-width: 300px;
    display: block;
    margin:0 auto;
  }
  h1,p{
    text-align: center;
  }
}
.register_popup_inner_right{
  width: 100%;
  padding: 20px ;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  select{
    background-color: #fff !important;
  }
  .MuiRadio-root *{
    color:  #83bd1b !important;
  }
  .MuiButton-contained{
    width:130px;
    height:40px;
    color:#fff;
    background-color: #83bd1b;
    display: block;
    margin: 0 auto;
    span{
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      text-transform: capitalize;
    }
  }
  .MuiTypography-body1{
    font-size: 14px;
  }
  .react-tel-input{
    height:38px;
    input{
      height:38px;
      padding: 14px 42px !important;
    }
  }
  input ,select,textarea{
    border: 1px solid #D3D3D3;
    border-radius: 5px;
    padding: 10px 15px;
    ::placeholder{
      color: #808080;
      font-weight: 400;
    }
  }
}
}

.highlight_popup{
  .hp_close_btn{
    position: absolute;
    top: -25px;
    right: -30px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    color:  #83bd1b;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
  }
  flex-direction: column;
  padding: 20px;
  height: fit-content;
  overflow: visible;
  .highlight_popup_top{
    background: #c9eeff;
    border-radius: 12px 12px 0px 0px;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    h1{
      max-width: 350px;
      font-size: 45px;
      color: #000;
      font-weight: bold;
      width: 50%;
    }
    .highlight_popup_top_right{
      width: 50%;
      h3{
        font-size: 32px;
        font-weight: normal;
        text-align: center;
        margin: 0;
      }
      >div{
        display: flex;
        .green_round_con{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 33.3%;
          h5{
            font-size: 22px;
            position: relative;
            span{
              font-size: 14px;
              position: absolute;
              top: 0;
            }
          }
          .green_round{
            width: 70px;
            height: 70px;
            background: #1CB370;
            font-weight: 700;
            font-size: 25px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
      }
    }
  }
  .highlight_popup_medium{
    display: flex;
    justify-content: space-between;
    >div{
      width: 49%;
      background: #efe8b3;
      min-height: 100px;
      margin: 20px 0;
      display: grid;
      grid-template-columns: 1fr 5fr;
      align-items: center;
      padding: 10px 15px;
      >img{
      }
      >div{
        p{
          font-size: 23px;
          color: #303030;
          span{
            color: #B32B2D;
            font-weight: bold;
          }
        }
      }
    }
    >div:first-child{
      >div{
        p{
          span{
            font-size: 35px;
          }}}
    }
  }
  .highlight_popup_bottom{
    display: flex;
    justify-content: space-between;
    >div{
      width: 32%;
      background: #efe8b3;
      min-height: 100px;
      display: grid;
      grid-template-columns: 1fr 5fr;
      align-items: center;
      grid-gap: 10px;
      padding: 10px 18px;
      >img{
      }
      >div{
        p{
          font-size: 18px;
          color: #303030;
          margin: 0;
          span{
            color: #B32B2D;
            font-weight: bold;
          }
        }
      }
    }
  }
}



#courses{
  section{
    padding: 40px 0;
  }
  .about_free_certification{
    background-size: cover;
    background-repeat: no-repeat;
    h2{
      text-align: center;
      font-size: 40px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    
    .about_free_certification_left_inner{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 600px;
      .hos_small_box{
        z-index: 2;
        border-radius: 20px;
        background-color: #efe8b3;
        height: 170px;
        width: 170px;
        margin: 20px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          text-align: center  ;
        }
        
      }
      .hos_small_box:first-child,.hos_small_box:last-child{
        background-color: #c9eeff;
      }
    }
    .about_free_certification_left_inner::after{
      content: '';
      position: absolute;
      z-index: 1;
      border-radius: 20px;
      background-color: #efe8b3;
      height: 170px;
      width: 170px;
      background: rgba(255, 255, 255, 0.01);
      opacity: 0.7;
      box-shadow: 0px 0px 144px rgba(0, 0, 0, 0.25);
    }
   
    
  }

  .skills_and_tools{
    h2{
      font-size: 45px;
      text-align: center;
      ~ p{
        max-width: 900px;
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
    h3{
      font-size: 30px;
    }
    .skills_and_tools_inner{
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      li{
        margin-top: 10px;
      }
      li:hover{
        svg,path{
          fill: #01B170;
        }
      }
    }
    
    .skill_tools_con{
      display: flex;
      flex-wrap: wrap;  
      justify-content: center;
      .skill_tools{
        margin: 10px;
        width: 140px;
      }
    }
    
  }


  .course_syllabus{    
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    .row{
      display: flex;
      flex-wrap: wrap;
    }
    .course_syllabus_inner_left{
      ul{
        li{ 
          border: 1px solid #c9eeff;
          padding: 10px 20px;
          cursor: pointer;
          transition: all 0.5s;
          h4{
            font-size: 18px;
          }
          p{
            display: none;
          }
        }
        .course_syllabus_acc_active{
          h4{
            font-size: 16px;
            color:  #83bd1b;
            font-weight: bold;
          }
          p{
            display: block;
          }
        }
      }
    }
    h2{
      font-size: 45px;
      ~ p{
        max-width: 900px;
        margin-bottom: 20px;
        font-size: 16px;
       
    }
  }
  button{
    width: 200px;
    height: 45px;               
    background-color:  #83bd1b;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    border: transparent;
    margin-bottom: 20px;
  }
  } 


.more_digital_marketing_courses{
  h2{
    font-size: 45px;
  }
  .more_digital_marketing_courses_list{
    display: flex;
    flex-direction: column;
    margin: 30px 20px;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
    box-shadow: 0px 2px 6px 0px #00000026;
    overflow: hidden;
    transition: all 0.5s;
    

    >img{
      width: 100%;
      object-fit: cover;
      margin-bottom: 10px;      
      height: 150px;
    }
    >div{
      display: flex;
      flex-direction: column;
      padding: 15px;
     p{
       span{
         display: none;
       }
     }
      >div{
        display: flex;
        justify-content: space-between;
        >span{
          display: flex;
          align-items: center;
          color: #606060;
          font-size: 14px;
          img{
            width: 20px;
            margin-right: 10px;
          }
         
        }
        >a{
          color:  #83bd1b;
          display: flex;
          align-items: center;
          cursor: pointer;
          path  {
            fill: #83bd1b ;
          }
        }
      }
    }
  }

  .more_digital_marketing_courses_list:hover{
    box-shadow: 0px 10px 20px 0px #00000033;
    >div{
      p{
        span{
          display: inline;
        }
      }
    }
  }

}
.more_digital_marketing_courses .slick-slider.slick-initialized{
  min-height: 575px;
}

}

@media (max-width:767px) {
  .website_header{
    position: fixed;
  }
  #website_content{
    margin-top: 100px ;
  }
  .website_header .nav_con{
    position: fixed;
  }
  .website_header .nav_link a{
    font-size: 18px !important;
  }
  .website_header .nav_link_btn_green button{
    font-size: 18px !important;
  }

  #home .counter_section{
    span{
      font-size: 26px !important;
    }
  }
  #home .counter_area .container{
    height: 130px;
  }
  #home .highlights_our_course .highlights_our_course_inner_right h2{
    text-align: center;
  }
  #home .highlights_our_course .highlights_our_course_inner_left .hos_small_box > div h4,#home .highlights_our_course .highlights_our_course_inner_left .hos_big_box > div h4{
    font-size: 38px !important;
  }
  .website_our_courses {
    padding-bottom: 0 !important;
  }
  .methodology_sec{
    padding: 0 !important;
  }

  .register_popup{
    flex-direction: column;
    height: 650px;
    .register_popup_inner_left{
      width: 100% !important;
      height: 180px;
    }
    .register_popup_inner_right{
      width: 100% !important;
      height: calc(100% - 200px);
    }
  }
  .register_popup .register_popup_inner_left img{
    display: none ;
  }
  .register_popup h1{
    font-size: 18px;
  }
  .highlights_our_course{
    padding: 0 !important;
  }
  #home .highlights_our_course{
    .col-lg-7{
      display: block;
      margin: auto;
    }
    .highlights_our_course_inner_left {
    min-height: 330px;
  
  }
}
#home .highlights_our_course .highlights_our_course_inner_left >div>div{
  top: 10% !important;
}
  #home .website_our_courses{
    #desk_website_our_courses{
      display: none;
    }
    #mbl_website_our_courses{
      display: block;
      .card-body{        
        padding: 20px !important;
        background-color: #fff;
        .course_card{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background-color: #F8F8F8;
        box-shadow: rgb(0 0 0 / 30%) 0px 0px 40px, rgb(0 0 0 / 5%) 0px 15px 12px;
        padding: 15px;
      img{
        width: 100%;
        height: 200px;
        object-fit: cover;
        margin-bottom: 15px;
        ~p{
          font-size: 16px !important;
          padding: 0 20px;
          ~a{
            margin-right: 10px;
          }
          >span{
            font-size: 16px !important;
          }
        }
        }
      }
    }
    }

  } 
  #home .tools_you_master .master_tools_con{
    padding: 20px 0;
    max-height: 270px;
  }
  #home .projects_sec {
    padding: 5px 0;
  }
  #home .website_batch_detail .batch_pricing_box_inactive{
    display: none;
  }
  #home #courseacc_sec{
    margin: 10px 0 50px;
  }
  #home .tools_you_master {
    padding: 20px 0 0;
  }
  #home .rating_feedback .website_feedback_container{
    margin-top: 10px;
  }
  #home .website_batch_detail{
    padding:0;
  }
  #home .qa_sec{
    margin: 0;
    padding: 10px 0;
  }
  #home .website_batch_detail .row{
    margin-top: 0px !important;
  }
  #home #faq_sec{
    margin: 10px 0;
  }
  #home .projects_sec .thumb_img{
    display: none;
  }
  #home .projects_sec .mt-5 {
    margin-top: 25px;
  }

  #home .image_icon{
    position: relative;
    top: auto;
    right: auto;
    margin-bottom: 5px;
  }



  #courses section{
    padding: 15px 0;
  }
  #courses .course_syllabus h2{
    text-align: center;
  }
  #courses .course_syllabus h2 ~ p ~ button{
    margin: 0 auto 15px auto;
  }
  #home .website_certificate h2, #courses .website_certificate h2{
    margin-bottom: 0;
  }
  #courses .more_digital_marketing_courses{
    margin-bottom: 20px;
  }
  #courses .more_digital_marketing_courses_list{
    >div{
      p{
        span{
          display: inline !important;
        }
      }
    }
  }
  #home .website_certificate img, #courses .website_certificate img{
    width: 100%;
  }
  .highlight_popup {
    h1{
      font-size: 22px !important;
    }
    h3{
      font-size: 18px !important;
    }
    h5{
      font-size: 18px !important;
    }
    p,span{
      font-size: 18px !important;
    }
    .highlight_popup_top,.highlight_popup_medium,.highlight_popup_bottom{
      flex-wrap: wrap;
    }
    .highlight_popup_top{
      padding: 10px;
      margin-bottom: 5px;
      >div,>h1{
        width: 100%;
        max-width: 100%;
        margin-top: 0;
      }
      .highlight_popup_top_right{
        width: 100%;
        .green_round_con {

        .green_round{
          height: 50px !important;
          width: 50px !important;
          font-size: 18px !important;
        }
      }
      }
    }
    .highlight_popup_medium{     
      >img,>div{
        width: 100%;
        margin: 10px;
      }
      >div{
        padding: 5px 10px;
        margin:3px 0;
        grid-gap: 10px;
      }
      br{
        display: none;
      }
    }
    .highlight_popup_bottom{
      >div{
        width: 100%;
        padding: 0 10px;
        margin: 3px 0;
        min-height: 80px;
      }
      >div:last-child{
        border-radius:0px 0px 12px 12px ;
      }
    }
  } 

  #terms_condition_header{
    margin:0 auto;
    flex: none;
    display: initial;
  }
}
