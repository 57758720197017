/* @font-face {
    font-family: circular-bold;
    src: url('./fonts/Circular_Std_Bold.ttf');
}

@font-face {
    font-family: circular-regular;
    src: url('./fonts/Circular_Std_Book.ttf');
} */

body {
    font-family: circular-regular;
    background: #f3f3f3;
    position: relative;
}

h1,
h2,
h3,
h4 {
    /* margin: 10px 0 30px 0; */
    font-size: 20px;
    font-family: circular-bold;
}

  
/* section switching css start */
html {
    scroll-behavior: smooth;
  }

 /* #courses
 {
     float: left;
     margin-top: 500px; 
     top: 500px;
 }
 #syllabus
 {
     float: left;
 }
 #our_program
 {
     float: left;
 }
 #certification_sec
 {
     float: left;
 }
 #contact
 {
     float: left;
 } */

/* section switching css end */



/* Popup style */
.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
  }
  
  .box {
    position: relative;
    width: 58%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    color: black !important;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }

  .popup-box .box p{
   color:#000000a8 !important;
  }
 .popup_btn input[type="button"]
  {
    background-color: #23abf9 !important;
    color: white !important;
    padding: 7px 24px;
    border: none;
    font-size: 18px;
    border-radius: 3px;
  }
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(21.5% - 24px);
    top: calc(100vh - 85vh - 33px);
    background: #ededed;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
  }


.up_section p {
    font-family: circular-bold;
    text-transform: uppercase;
}

.top_accordian {
    margin-top: 70px;
    float: left;
    width: 100%;
}

.BackgroundVideo_Container__1ewG5{
    margin-bottom: 0px !important;
}

.ta_set .timerSet {
    font-size: 12px;
    font-family: circular-regular;
    padding-left: 40px;
    padding-top: 2px;
}

.top_accordian .collabration_set h1 {
    margin-bottom: 0px;
}

.accrdian_set {
    margin-top: 50px;
}

.panel-title {
    position: relative;
}

.panel-title a::after {
    content: "\f107";
    color: #000;
    top: 15px;
    right: 30px;
    position: absolute;
    font-size: 25px;
    font-family: "FontAwesome";
}

.panel-title a[aria-expanded="true"]::after {
    content: "\f106";
}

.panel-title a[aria-expanded="true"] {
    background: #fff;
}

.accrdian_set .panel-title {
    text-align: left;
}

.accrdian_set .panel-title a {
    text-decoration: none;
    display: block;
    padding: 20px;
    font-family: circular-bold;
    text-transform: capitalize;
}

.accrdian_set .panel-group {
    margin-bottom: 0px;
}

.accrdian_set .panel-default>.panel-heading {
    padding: 0px;
}

.accrdian_set .panel-body p {
    line-height: 28px;
    padding: 20px;
}

.accrdian_set .panel-body {
    overflow-y: hidden;
    height: auto;
}

.time_res {
    display: table;
}

.margin_set {
    display: table-cell;
    vertical-align: middle;
}

.content_set h1 {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    text-align: left;
    /* font-weight: bold; */
    margin: 14px 0px;
    font-family: circular-bold;
}

.set_wide {
    width: 80%;
    margin-bottom: 35px;
    float: initial;
    overflow: hidden;
    display: none;
    margin: 0 auto 35px;
}

div#all_ourprogram {
    float: left;
    width: 100%;
    padding: 50px 0px;
    background: #fff;
}

.set_small {
    width: 60%;
}

.set_wide:last-child .set_small {
    margin: 0px;
}

.load_buttons {
    text-align: center;
}

button#loadMore,
#load_accordian,
#load_less,
#load_Less {
    font-size: 18px;
    text-transform: capitalize !important;
    background: initial;
    color: #fff !important;
    margin: 15px 0px;
    color: #18a7f9 !important;
    /* float: left; */
    /* border-radius: 25px; */
    /* width: 130px; */
    /* height: 42px; */
    border: 0 !important;
    padding: 10px 0px;
    text-transform: uppercase;
    outline: initial;
    font-family: circular-bold;
    box-shadow: initial;
}

#load_less,
#load_Less {
    display: none;
}

.content_certi ul {
    width: 100%;
    float: right;
    margin-top: 15px;
}

.down_set {
    width: 21px;
    float: left;
    margin-right: 15px;
}

.content_certi p {
    font-size: 14px;
    line-height: 24px;
    font-family: circular-regular;
}

.content_certi li {
    position: relative;
    font-size: 18px;
    margin-bottom: 25px;
    line-height: 36px;
    list-style: none;
}

/* .slick-dots .slick-active {
    background: #009EF9;
}

.slick-dots li {
    background: #ddd;
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

.slick-dots {
    bottom: -50px;
} */

.certi_set {
    width: 85%;
}

#tools {
    text-align: center;
    float: left;
    width: 100%;
    margin: 50px 0px;
}

div#benefits {
    float: left;
    width: 100%;
    padding: 50px 0px;
    background: #fff;
}

.content_set p {
    color: #000;
    text-align: justify;
    line-height: 24px;
}

nav.sidebar .navbar-nav>li {
    border-bottom: 1px #e5e5e594 solid;
}
.navbar-nav{
    /* display: flex;
    flex-direction: column; */
    width: 100%; 
}
.last_bottom_nav{
    position: absolute !important;
}
.user_sidebar li:nth-child(5){    
    bottom:52px;
}
.user_sidebar li:nth-child(6){
    bottom: 0;
}
/* .slick-prev:before {
    content: '\f053';
    font-family: 'fontawesome'
}

.slick-next:before {
    content: '\f054';
    font-family: 'fontawesome'
}

.slick-prev {
    left: -100px;
}

.testimonial_slide .slick-prev {
    left: 10px;
    z-index: 99;
}

.testimonial_slide .slick-next {
    right: 10px;
}

.slick-next {
    right: -100px;
} */

/* .slick-arrow {
    border: 1px solid;
    background: rgb(177, 179, 195) !important;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 6px;
} */

/* #all_ourprogram .slick-slide {
    padding: 25px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    margin: 35px;
    outline: initial;
    border-radius: 10px;
} */

a.navbar-brand {
    width: 50%;
    height: auto;
}
.website_brand{
    width: 20%;
}
ul.nav.navbar-nav.navbar-right {
    width: 45%;
}

a.navbar-brand {
    margin-left: 50px !important;
    float: left;
}

.set_img i {
    font-size: 34px;
}

.collabration_set img {
    width: 45px;
    position: relative;
    top: -3px;
    display: inline-block;
}

.collabration_set .companies img {
    width: 100%;
}

.companies ul {
    padding: 0px;
}

.collabration_set .tab-content img {
    width: 100%;
}

.graph_set {
    width: 70%;
    /* padding: 100px; */
    margin: 60px auto 0px;
    display: inline-block;
}

.base_title {
    font-family: circular-bold;
    color: #000 !important;
    margin: 20px 0px;
    font-size: 24px !important;
}

.companies ul li {
    border: 1px solid #ddd;
    margin-bottom: 5px;
    width: 90%;
    float: right;
    padding: 10px 15px;
}

.companies {
    margin-top: 75px;
}

.nav-tabs>li {
    text-transform: uppercase;
}

.companies .nav-tabs {
    border: 0px;
}

.nav-tabs {
    border-bottom: 1px solid #009EF9;
    width: 60%;
    margin: 0a;
    margin: 0 auto;
}

.gr_set {
    margin-top: 30px;
    float: left;
    width: 100%;
}

.phone h5,
.email h5,
.address h5 {
    position: relative;
}

.phone h5:before,
.email h5:before,
.address h5:before {
    content: '\f095';
    font-family: fontawesome;
    position: absolute;
    left: -30px;
    top: 2px;
    font-size: 18px;
}

.email h5:before {
    content: '\f0e0';
}

.address h5:before {
    content: '\f041';
}

.nav-tabs li a {
    padding: 15px 30px 14px;
    border-radius: 0px;
    color: #000 !important;
    font-family: circular-bold;
    font-size: 18px;
}

.nav-tabs>li.active>a {
    padding: 15px 30px !important;
    background: #009EF9;
    color: #fff !important;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border: 0;
    background: #009EF9;
}

.nav-tabs>li>a:hover {
    border-color: #009EF9;
    background: #009EF9;
    color: #fff !important;
    transition: all 0.5s ease-in;
}

.companies ul li.active {
    border-color: #009EF9;
    webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    border: 2px solid #009EF9;
}

.bot_in {
    margin: 50px 0px;
    width: 75%;
    display: inline-block;
    float: initial;
}

#degree_exp {
    /* background-image: url('./images/degbanner.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 150px 0px;
    float: left;
    width: 100%;
}

.whole_deg {
    background: #fff;
    border-radius: 5px;
    padding: 40px 50px;
}

.digi_mrk h3 {
    text-transform: uppercase;
    font-family: circular-bold;
    font-size: 14px;
    position: relative;
    letter-spacing: 0px;
}

.digi_mrk h3:before {
    content: '';
    /* border: 1px solid; */
    position: absolute;
    height: 100%;
    width: 4px;
    background: #009EF9;
    border-radius: 5px;
    left: -8px;
    top: 0px;
}

.sm_iconset {
    float: left;
    margin-right: 8px;
    width: 17px;
}

.container {
    width: auto;
    max-width: 970px;
}

.lesson_page .container {
    max-width: 820px;
}

li {
    list-style: none
}

.digi_mrk h1 {
    text-transform: capitalize;
    font-size: 36px;
    font-family: circular-bold;
}

.digi_mrk ul {
    padding-left: 18px;
}

.digi_mrk li {
    margin-bottom: 10px;
    position: relative;
    color: #666;
}

.digi_mrk h5 {
    font-family: circular-bold;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: #009EF9;
}

.rate_out {
    display: inline-block;
    float: right;
}

.digi_mrk li:before {
    content: '\f111';
    position: absolute;
    left: -18px;
    font-family: fontawesome;
    font-size: 10px;
    top: 2px;
}

.sm_out {
    padding: 0;
    margin-top: 50px;
}

.sm_out .sm_box:nth-child(2) {
    float: right;
}

.sm_box {
    background: #fff;
    border-radius: 5px;
    padding: 30px;
}

.top_rating p i {
    color: #FF3D3D;
    margin-right: 30px;
}

.testimonial_slide {
    float: left;
    width: 100%;
}

.trv_img {
    float: left;
    width: 100%;
    margin-top: 50px;
}

.top_rating p {
    font-size: 28px;
    display: inline-block;
    color: #000;
    font-family: circular-regular;
    font-weight: bold;
}

.rate_set_out>span {
    color: #000;
    text-transform: capitalize;
    font-size: 16px;
}

.padding_none {
    padding: 0px;
}

div#reviews {
    margin: 50px 0px;
    float: left;
    width: 100%;
}

.rate_set {
    width: 100px;
    display: inline-block;
    margin-left: 13px;
}

.top_rating div p {
    font-size: 18px;
    text-align: right;
    display: block;
    color: #009EF9;
}

.top_rating .testimonial_set {
    background: #fff;
    padding: 40px 100px;
    border-bottom: 8px solid #009EF9;
    margin-top: 30px;
}

.testimonial_set h5 {
    text-transform: uppercase;
    font-family: circular-bold;
    color: #009EF9;
}

.top_rating .testimonial_set p {
    text-align: left;
    line-height: 24px;
    color: #000;
    font-weight: normal;
    font-family: circular-regular;
    font-size: 14px;
}

.sm_box p {
    font-family: circular-bold;
    margin-bottom: 15px;
    line-height: 24px;
}

.sm_box span {
    color: #009EF9;
    font-size: 24px;
}

.sm_box button {
    float: right;
    font-family: circular-bold;
    border-radius: 32px;
    background: #FFC300;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    padding: 15px 25px;
}

.sm_box h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000;
    font-weight: bold;
}

.digi_mrk p {
    line-height: 24px;
    color: #666;
}

.person {
    border: 10px solid transparent;
    margin-bottom: 25px;
    width: 80%;
    height: 80%;
    opacity: 0.7;
}

.person:hover {
    border-color: #f1f1f1;
}

.carousel-inner img {
    -webkit-filter: grayscale(90%);
    filter: grayscale(90%);
    /* make all photos black and white */
    width: 100%;
    /* Set width to 100% */
    margin: auto;
}

.carousel-caption h3 {
    color: #fff !important;
}

@media (max-width: 600px) {
    .carousel-caption {
        display: none;
        /* Hide the carousel text when the screen is less than 600 pixels wide */
    }
}

.bg-1 {
    background: #2d2d30;
    color: #bdbdbd;
}

.bg-1 h3 {
    color: #fff;
}

.bg-1 p {
    font-style: italic;
}

.list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.thumbnail {
    padding: 0 0 15px 0;
    border: none;
    border-radius: 0;
}

.thumbnail p {
    margin-top: 15px;
    color: #555;
}

.btn1 {
    padding: 10px 20px;
    background-color: #333;
    color: #f1f1f1;
    border-radius: 0;
    transition: .2s;
}

.btn1:hover,
.btn1:focus {
    border: 1px solid #333;
    background-color: #fff;
    color: #000;
}

.modal-header,
.close {
    text-align: center;
    font-size: 30px;
}

.modal-header,
.modal-body {
    padding: 40px 50px;
}

.nav-tabs li a {
    color: #777;
}

#googleMap {
    width: 100%;
    height: 400px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.navbar {
    font-family: Montserrat, sans-serif;
    margin-bottom: 0;
    border: 0;
    font-size: 11px !important;
    letter-spacing: 4px;
    opacity: 0.9;
}

.navbar-header {
    width: 30%;
}

#myVideo {
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 81px;
}

.navbar-default {
    z-index: 10;
    background: #fff;
    box-shadow: 9px 2px 4px #0000002e;
}

.navbar li a,
.navbar .navbar-brand {
    color: #000!important;
    font-family: circular-regular;
}

.navbar-nav li a:hover {
    color: #666 !important;
}

.navbar-nav li.active a {
    color: #fff !important;
    background-color: #f5f5f5 !important;
}

.navbar-default .navbar-toggle {
    border-color: transparent;
}

i.fa.fa-twitter-square {
    color: #00acee;
}

.open .dropdown-toggle {
    color: #fff;
    background-color: #555 !important;
}

.dropdown-menu li a {
    color: #000 !important;
}

.dropdown-menu li a:hover {
    background-color: red !important;
}

footer .container {
    max-width: 1170px;
}

.set_wide .col-lg-3 {
    float: left;
}

footer {
    background-color: #424242;
    color: #fff;
    padding: 32px;
    background: #07033a;
    /* background-image: url(./images/map.png); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

footer a {
    color: #f5f5f5;
    display: block;
    line-height: 36px;
}

.contact_set h5 {
    margin: 0px;
}

.contact_set>h5 {
    margin-bottom: 15px;
    margin-top: 10px;
}

.phone,
.email,
.address {
    margin-bottom: 15px;
}

footer li {
    float: left;
    margin-right: 10px;
    font-size: 24px;
}

.companies ul li.active {
    background: #009ef9;
    color: #fff;
}

.companies ul li {
    cursor: pointer;
    font-family: circular-bold;
    font-size: 18px;
}

.about_sec p {
    line-height: 26px;
    max-width: 100%;
}

.address h5 {
    margin-bottom: 10px;
    float: left;
    width: 100%;
}

footer h5 {
    font-family: circular-regular;
    font-weight: bold;
    font-size: 18px;
}

footer ul {
    padding: 0px;
}

footer a:hover {
    color: #777;
    text-decoration: none;
}

.form-control {
    border-radius: 0;
}

textarea {
    resize: none;
}

.set_border {
    border: 1px solid #424242;
    display: inline-block;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    padding: 13px;
    font-size: 16px;
    margin-bottom: 50px;
}

.collabration_set h1 {
    font-size: 26px;
    margin-bottom: 60px;
    display: inline-block;
    margin-left: 25px;
    font-weight: bold;
}

#what_learn {
    margin-bottom: 50px;
    background: initial;
    float: left;
    width: 100%;
}

.be_content_set {
    position: relative;
    right: 0;
    text-align: center;
    left: 0;
    /* top: 70%; */
    margin: 0 auto;
    display: inline-block;
    color: #fff;
}

.be_content_set h1
{
 font-size: 50px;
 margin-top: 0px;
}

.be_content_set p
{
    font-size: 22px !important;
}

.mfp-container {
    top: 10%;
}

.video {
    color: #fff;
    display: b;
    display: block;
    width: 50px;
    margin: 0 auto;
}

.sgin_form {
    margin: 0 auto;
    float: left;
}

ul#basicUsage {
    padding: 0;
    float: initial;
    width: 51%;
    margin: 0 auto 30px;
    display: inline-block;
}

ul#basicUsage li:last-child {
    padding: 0px;
}

ul#basicUsage li {
    float: left;
    font-size: 48px;
    padding-right: 30px;
}

ul#basicUsage p {
    font-size: 28px;
    margin: 0;
    text-transform: uppercase;
}

.entroll_but {
    float: left;
    width: 100%;
}

.social_set li {
    float: left;
    list-style: none;
    width: 20%;
    margin-right: 43px;
    padding: 29px;
    position: relative
}

.social_set ul {
    float: left;
    padding: 20px;
    margin: 0;
}

div#certification {
    padding-top: 50px;
    margin-bottom: 50px;
    float: left;
    width: 100%;
}

#certification .container {
    max-width: 1170px;
}

.social_set li:after {
    content: '';
    border: 0.5px solid #666;
    position: absolute;
    height: 76%;
    top: 12px;
    right: -8px;
}

.page-header {
    padding-bottom: 9px;
    margin: 40px 0 60px;
    border-bottom: 0;
}

.page-header p {
    text-align: center;
    font-size: 16px;
    text-transform: capitalize;
    color: #009EF9;
    font-family: circular-bold;
}

.social_set {
    padding: 0px;
    float: left;
    width: 100%;
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
}

#what_learn h1 {
    text-transform: capitalize;
    text-align: center;
    color: #000;
    margin-bottom: 15px;
    font-weight: initial;
    font-family: circular-bold;
}

#myCarousel {
    float: left;
    width: 100%;
    position: relative;
    overflow: h;
    overflow: hidden;
    height: 700px;
}

.banner_content {
    padding: 0;
    color: #000;
    text-align: center;
    position: absolute;
    background: #fff;
    height: 100%;
    left: 0;
    right: 0;
    top: 250px;
    width: 675px;
    margin: 0 auto;
}

.pop_video {
    width: 100%;
    float: left;
    font-size: 60px;
}

.banner_content h1 {
    font-size: 36px;
    text-transform: capitalize;
    /* font-weight: bold; */
    font-family: circular-bold;
}

.layer_set {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000002e;
    top: 0px;
    left: 0;
    z-index: 9999;
}

.content_certi h3 {
    margin: 0px 0px 15px 0px;
    font-family: circular-bold;
    font-size: 18px;
}

.sign_in {
    text-transform: capitalize !important;
    background: #009EF9 !important;
    color: #fff !important;
    margin: 15px 0px;
    /* float: left; */
    border-radius: 25px;
    width: 130px;
    /* height: 42px; */
    border: 0 !important;
    padding: 15px 0px;
    text-transform: uppercase;
    outline: initial;
    font-family: circular-bold;
}

#myCarousel:after {
    content: '';
    background-color: #000000c7;
    position: absolute;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
}

.banner_content p {
    font-size: 16px;
    font-weight: initial;
    margin-bottom: 30px;
}

#basicUsage {
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
}

.border_unset:after {
    display: none;
    border-right: 0px;
}

.collabe_with {
    padding: 50px 0px 50px 0px;
}

.syllabus,
.entroll {
    background: #009EF9 !important;
    outline: 0 !important;
    color: #fff !important;
    /* float: left; */
    border-radius: 25px;
    width: 200px;
    /* height: 42px; */
    border: 0 !important;
    padding: 15px 0px;
    text-transform: uppercase;
    outline: initial;
    font-family: circular-bold;
}

.entroll {
    float: left;
    margin-right: 0;
}

.syllabus {
    float: left;
    margin-left: 0;
    margin-right: 40px;
    margin-left: 0px;
}

.al_center {
    display: inline-block;
}

.learn_back {
    margin-top: 30px;
    padding: 0px 0px;
    float: left;
    width: 100%;
    /* background-image: url(./images/download_back.png); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
}

.perequiste {
    float: left;
    width: 100%;
}

.nurture_set {
    margin-bottom: 60px;
}

img {
    max-width: 100%;
    /* width: 100%; */
}

.train_set {
    margin-top: 0px;
    text-align: center;
}

.emi .exp_set {
    float: left !important;
    width: 60px;
    margin-right: 15px;
}

.payementDetail {
    float: left;
    margin-top: 30px;
}

.navbar-default .navbar-nav>li>a {
    font-size: 17px;
    letter-spacing: 0px;
    padding: 30px 23px;
    font-family: circular-regular;
    color: #5c5c5c !important;
}

.set_img {
    height: 47px;
    width: 45px;
    margin: 0 auto;
}

.pre_desc h3 {
    text-transform: capitalize;
    margin: 0;
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 10px;
    display: inline-block;
    position: relative;
    font-family: circular-bold;
    color: #000;
}

.pre_desc p {
    color: #000;
    /* font-weight: bold; */
    text-transform: capitalize;
    font-family: circular-regular;
}

.pre_desc p:nth-child(3) {
    font-weight: initial;
    text-transform: capitalize;
    font-family: circular-regular;
}

#all_ourprogram .collabration_set img {
    width: 30px;
}

/* .rating_slick {
    width: 100%;
    margin: 0 auto;
    float: initial;
    text-align: center;
    display: inline-block;
} */

.f_child {
    float: left !important;
}

.whole_slide {
    text-align: center;
    max-width: 1170px;
}

.sm_setting {
    border: 1px solid #ddd;
    min-height: 490px;
    border-radius: 5px;
    padding: 25px;
    float: initial;
    display: inline-block;
    /* width: 68% !important; */
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    max-width: 31%;
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    /* margin: 35px; */
    outline: initial;
    border-radius: 10px;
}

.l_child {
    margin: 0;
    float: right;
}

.slide_img img:nth-child(2) {
    display: inline-block;
    width: 100px;
    float: right;
    margin: 20px 0px;
}

.content_set {
    float: left;
    width: 100%;
}

.margin_set {
    background: #fff;
    /* border: 1px solid #fff; */
    padding-top: 20px;
    min-height: 200px;
    /* -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75); */
    /* box-shadow: 0px 0px 3px 0px #ff0000cc; */
    border-radius: 5px;
    width: 250px;
    height: 250px;
    padding: 0px 20px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
}

div#banner_bottom:before {
    content: '';
}

.bg_grey {
    background: #f3f8fe;
}

#banner_bottom .on_set {
    margin: 60px 0px 0px 15px;
}

#banner_bottom {
    position: relative;
    background: #fff;
    float: left;
    width: 100%;
    text-align: center;
    max-width: 100%;
    margin-bottom: 50px;
}

.collabration_set {
    margin: 0px 0px 0px;
    color: #000;
    float: left;
    width: 100%;
    text-align: center;
}

#timeline {
    list-style: none;
    position: relative;
}

#timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #6666662e;
    left: 50%;
    margin-left: -1.5px;
}

#timeline .clearFix {
    clear: both;
    height: 0;
}

.timeline-badge-two.three_set,
.timeline-badge.three_set {
    border: 1px solid #666;
    width: 80px !important;
    height: 0 !important;
    border-style: dashed;
    left: 53% !important;
    background-color: initial !important;
    top: 0;
    top: 119px !important;
}

.timeline-movement .timeline-badge.three_set {
    top: 44px !important;
    left: 44% !important;
}

.first_setting .timeline-badge.three_set {
    left: 44% !important;
}

#timeline .timeline-badge-two {
    color: #fff;
    width: 10px;
    height: 10px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 115px;
    left: 52%;
    margin-left: -25px;
    background-color: #FFC300;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

#benefits p {
    color: #666;
    font-size: 16px;
    text-transform: capitalize;
    line-height: 26px;
}

.trv_desc {
    /* margin-top: 50px; */
    /* margin-top: 50px; */
    float: initial;
    width: 90%;
    margin: 0px auto 0px;
    display: inline-block;
    text-align: center;
    padding: 50px 0px 80px;
}

.trv_desc>div {
    margin-bottom: 20px;
    float: left;
    width: 100%;
}

.trv_desc>div h5:before {
    content: '';
    /* border: 1px solid; */
    position: absolute;
    height: 100%;
    width: 4px;
    background: #FF3D3D;
    border-radius: 5px;
    left: -8px;
    top: -1px;
}

.trv_desc>p {
    color: #fff;
    line-height: 24px;
    float: left;
    font-size: 16px;
    position: relative;
    left: 0;
    text-align: center;
}

.train_set .entroll {
    float: initial;
    margin-right: 0;
    display: inline-block;
    margin-top: 20px;
    background: #fff;
    color: #000;
}

.trv_desc>h4 {
    float: left;
    line-height: 60px;
    /* padding-bottom: 20px; */
    width: 100%;
    background: initial;
    color: #fff !important;
    position: relative;
    left: -10px;
    font-size: 32px;
    margin-bottom: 10px;
    font-family: circular-bold;
}

.trv_desc>div h5 {
    display: inline-block;
    font-size: 16px;
    font-family: circular-bold;
    text-transform: uppercase;
    color: #009EF9;
    margin: 0;
    position: relative;
    padding: 2px 0px;
    font-family: circular-bold;
}

.trv_desc>div a {
    color: #009EF9;
    float: right;
    display: inline-block;
    font-family: circular-bold;
    /* margin-bottom: 20px; */
}

#timeline .timeline-badge {
    color: #fff;
    width: 10px;
    height: 10px;
    font-size: 1.2em;
    text-align: center;
    position: absolute;
    top: 40px;
    left: 52%;
    margin-left: -25px;
    background-color: #FFC300;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

#timeline .timeline-badge span.timeline-balloon-date-day {
    font-size: 1.4em;
}

#timeline .timeline-badge span.timeline-balloon-date-month {
    font-size: .7em;
    position: relative;
    top: -10px;
}

#timeline .timeline-badge.timeline-filter-movement {
    background-color: #ffffff;
    font-size: 1.7em;
    height: 35px;
    margin-left: -18px;
    width: 35px;
    top: 40px;
}

#timeline .timeline-badge.timeline-filter-movement a span {
    color: #4997cd;
    font-size: 1.3em;
    top: -1px;
}

#timeline .timeline-badge.timeline-future-movement {
    background-color: #ffffff;
    height: 35px;
    width: 35px;
    font-size: 1.7em;
    top: -16px;
    margin-left: -18px;
}

#timeline .timeline-badge.timeline-future-movement a span {
    color: #4997cd;
    font-size: .9em;
    top: 2px;
    left: 1px;
}

#timeline .timeline-movement {
    /*border-bottom: dashed 1px #4997cd;*/
    position: relative;
}

#timeline .timeline-movement.timeline-movement-top {
    height: 60px;
}

.off_set {
    margin-top: 70px;
}

#timeline .off_set_ul {
    margin-left: 70px !important;
}

#timeline .timeline-movement .timeline-item .timeline-panel {
    color: #666;
    padding: 10px;
    position: relative;
}

#timeline .timeline-movement .timeline-item .timeline-panel .timeline-panel-ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul {
    text-align: left;
    width: 70%;
    float: left;
}

#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul li {
    color: #666;
}

.timeline-panel-ul h2 {
    margin: 10px 0px;
    color: #000;
    font-family: circular-bold;
    text-transform: uppercase;
    font-size: 16px;
}

.timeline-panel-ul P {
    line-height: 24px;
}

#timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul li span.importo {
    color: #468c1f;
    font-size: 1.3em;
}

#timeline .timeline-movement .timeline-item .timeline-panel.debits .timeline-panel-ul {
    text-align: left;
}

#timeline .timeline-movement .timeline-item .timeline-panel.debits .timeline-panel-ul span.importo {
    color: #e2001a;
    font-size: 1.3em;
}

.shade_img {
    width: 80px;
    margin: 0 auto;
}


/*Dashboard*/

.chat {
    list-style: none;
    margin: 0;
    padding: 15px;
}

.panel-primary {
    border: 0;
    border-radius: 5px;
    -webkit-box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 1px 0px 8px 0px rgba(0, 0, 0, 0.12);
}

.primary-font {
    color: #000;
}

.chat li {
    position: relative;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.chat_per {
    padding-top: 10px;
    font-family: circular-bold;
}

.chat_per span {
    font-family: circular-regular;
}

.chat li.left .chat-body {
    margin-left: 60px;
}

.chat li.right .chat-body {
    margin-right: 60px;
}

.chat li .chat-body p {
    margin: 0;
    padding-top: 5px;
    color: #000;
}

.panel .slidedown .glyphicon,
.chat .glyphicon {
    margin-right: 5px;
}

.panel-body {
    overflow-y: scroll;
    padding: 0px;
    height: 300px;
}

.day_set {
    margin: 0;
    padding: 10px 15px;
    border-bottom: 1px solid #B4B1AE;
    color: #B4B1AE;
}

button#btn-chat {
    background: initial;
    color: #000;
    padding: 3px 15px;
    font-size: 18px;
    border-color: #ddd;
}

.text-muted {
    color: #b4b1ae;
    font-family: circular-bold;
    font-size: 13px;
}

.chat-img p:after {
    content: '';
    border: 2px solid #fff;
    /* border: coral; */
    border-radius: 100%;
    position: absolute;
    width: 15px;
    height: 15px;
    background: #1abae7;
    bottom: 0px;
}

.chat-img p.active:after {
    background: #ddd;
}

.chat-img p {
    position: relative;
    background: #ddd;
    padding: 10px;
    border-radius: 100%;
}

.avl_time {
    float: right;
}

.chat-body:hover .chat_action {
    opacity: 1;
}

.chat_action {
    opacity: 0;
    float: right;
    cursor: pointer;
    transition: all 0.5s ease-in;
}

.del_msg a {
    color: #000;
    text-decoration: none;
}

.del_msg {
    display: none;
    position: absolute;
    right: 30px;
    width: 150px;
    top: 0px;
    background: #fff;
    webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    z-index: 9;
    padding: 15px 0px 0px;
}

.smart_chat .panel-primary {
    border: 0 !important;
}

.smart_chat .s_logo {
    width: 20px;
    display: inline-block;
}

.smart_chat.visible {
    opacity: 1;
}

.smart_chat {
    opacity: 0;
    transition: all 0.5s ease-in;
    display: block;
    bottom: 30px;
    right: 200px;
    position: fixed;
    width: 420px;
    z-index: 999999;
}

.avl_time_out {
    font-size: 12px;
}

.panel-primary>.panel-heading {
    background-color: initial;
    border-color: #B4B1AE;
    color: #000;
}

.prof_detail.active {
    background: rgba(158, 158, 158, 0.07) !important;
}

.smart_chat a.btn.btn-default.btn-xs {
    background: initial;
    border: 0px;
    padding: 0;
    position: relative;
    font-size: 19px;
    top: -2px;
    color: #fff;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    width: 100%;
}

.r_dahboard {
    margin: 0px;
}

.chat_bot {
    position: fixed;
    right: 100px;
    bottom: 15px;
    width: 100px;
}

.dashboard {
    margin-top: 150px;
    float: left;
    width: 100%;
}

.top_images {
    width: 30px;
    display: inline-block;
    text-align: right;
    margin-left: 20px;
}

.dashboard ul.nav.navbar-nav.navbar-right {
    width: 30%;
}

.sub_menu {
    width: 100%;
    position: absolute;
    background: #fff;
    bottom: auto;
    top: 100%;
    padding: 0px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.28);
}

.dashboard .navbar-default .navbar-nav>li>a {
    color: #000 !important;
    font-size: 14px;
}

.dashboard .navbar-nav>li {
    min-width: 40%;
}

.dashboard .sub_menu a {
    letter-spacing: 0px;
    font-size: 14px;
    text-decoration: none;
    font-family: circular-regular;
    color: #000 !important;
    padding: 10px;
    display: block;
    border-bottom: 1px solid #ddd;
}



.last_activity h4 {
    text-align: left;
    font-size: 16px;
    margin: 0px 0px 15px 0px;
}

.last_activity h4 span {
    width: 25px;
    display: inline-block;
    margin-right: 10px;
}

.course_continue {
    background: #009EF9;
    color: #fff !important;
    margin: 15px 0px;
    /* float: left; */
    display: block;
    border-radius: 25px;
    text-align: center;
    width: 100%;
    /* height: 42px; */
    border: 0 !important;
    text-decoration: none !important;
    padding: 15px 0px;
    text-transform: capitalize;
    outline: initial;
    font-family: circular-bold;
    margin: 20px 0px 0;
}

.prof_detail {
    position: relative;
}

.last_activity h5 {
    font-family: circular-bold;
    font-size: 16px;
    text-transform: capitalize;
}

.dashboard .top_title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    font-family: circular-regular;
    color: #666;
    font-weight: bold;
}

.last_activity p {
    color: #666;
}

.last_activity {
    padding: 20px;
    margin-bottom: 40px;
    border: 0;
    background: #fff;
    border-radius: 5px;
    box-shadow: 3px 6px 7px -1px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 3px 6px 7px -1px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 3px 6px 7px -1px rgba(0, 0, 0, 0.25);
    -o-box-shadow: 3px 6px 7px -1px rgba(0, 0, 0, 0.6);
}

.dashboard h6 {
    font-size: 14px;
    font-family: circular-regular;
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
}

.footer ul {
    float: left;
    padding: 15px 0px 30px 15px;
    border-top: 1px solid #ddd;
    width: 100%;
}

.footer ul li a {
    color: #000;
    font-family: circular-regular;
    font-weight: bold;
    margin-right: 50px;
}

.footer ul li {
    float: left;
}

@media only screen and (max-width:1349px) {
    .dashboard ul.nav.navbar-nav.navbar-right {
        width: auto;
    }
    .dashboard .navbar-nav>li {
        min-width: auto;
    }
}

@media only screen and (max-width: 767px) {
    .navbar-default .navbar-collapse,
    .smart_chat {
        right: 130px;
    }
}

@media only screen and (max-width:481px) {
    .dashboard .container,
    .dashboard .container-fluid {
        padding: 0;
    }
    .smart_chat {
        right: 0;
    }
    .chat_bot {
        width: 70px;
    }
}


/*Dashboard*/


/*Lesson Detail*/


/* Tabs*/

.video_choose .section-title {
    text-align: center;
    color: #007b5e;
    margin-bottom: 50px;
    text-transform: uppercase;
}

.accordion-toggle:after {
    font-family: 'FontAwesome';
    content: "\f078";
    float: right;
}

.accordion-opened .accordion-toggle:after {
    content: "\f054";
}

.video_choose h6.section-title {
    color: #eee;
}

.video_choose .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #f3f3f3;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    font-size: 20px;
    font-weight: bold;
}

.out_note {
    font-size: 14px;
    margin-top: 35px;
    font-weight: initial;
}

.ta_set .below_set {
    font-size: 14px;
    margin-top: 30px;
    padding-left: 15px;
    max-width: 95%;
}

.r_dahboard .react-tabs__tab.react-tabs__tab--selected :after {
    content: '';
    border-bottom: 3px solid rgb(237, 50, 58) !important;
    width: 100%;
    position: absolute;
    left: 0;
    margin: 0 auto;
    right: 0;
    bottom: -18px;
}

.react-tabs__tab-list {
    margin: 0px;
    border: 0px !important;
}

.accordion__button span {
    border: 1px solid;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    display: inline-block;
    padding: 2px 0px;
    margin-right: 10px;
}

.accordion__button[aria-expanded=true] {
    background-color: #efefef !important;
    outline: 0 !important;
}

.r_dahboard .react-tabs__tab,
.react-tabs__tab-list {
    border: 0px;
}

.r_dahboard .react-tabs__tab p {
    padding-bottom: 10px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #000;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    padding: 0px;
    text-align: center;
}

.innerVideo {
    color: #fc2c2b !important;
    display: flex;
    text-decoration: none !important;
    justify-content: space-evenly;
}

.video_choose .nav-tabs .nav-link {
    padding-bottom: 10px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #000;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    width: 48%;
    display: inline-block;
    text-align: center;
}

a.accordion-toggle {
    /* FONT-VARIANT: JIS04; */
    display: block;
    border-bottom: 1px solid #ddd;
    padding: 25px 0px;
    color: #666;
    font-family: circular-bold;
}

.video_choose div#nav-tabContent {
    margin-top: 0px;
}

.video_choose .nav-tabs {
    width: 100%;
    border: 0;
}

section#tabs {
    padding: 0;
}

.ta_set p {
    font-family: circular-bold;
    margin-bottom: 30px;
    font-size: 18px;
    padding-left: 30px;
    text-transform: capitalize;
}

.ta_set h5 {
    padding-left: 30px;
    padding-top: 10px;
}

.accordion-inner {
    padding: 15px 0px;
}

a.accordion-toggle span {
    border: 1px solid;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    display: inline-block;
    padding: 2px 0px;
    margin-right: 10px;
}

.video_choose {
    padding: 0px 0px !important;
    float: left;
    margin-top: 30px;
    width: 100%;
    background: #fff;
    min-height: 80vh;
}

.ta_set {
    background: #fff;
    position: relative;
    right: 0;
    /* top: 100px; */
    /* transition: all 5s; */
    height: calc(100vh - 100px);
    width: 100%;
    bottom: 0;
}
@media (min-width:1200px) {
   .ta_set {
       width: 25%;
   }
}


/*Lesson Detail*/


/*Lesson*/

.lat_activity {
    color: #fff;
}

.sidebar_out {
    position: fixed;
    top: 0;
    bottom: 0;
    /* max-height: 630px; */
    height: 100vh;
    z-index: 100;
}

.lesson_page {
    margin: 0px;
}

.lesson_page .navbar-inverse .navbar-nav>li>a span svg{
    width: 24px;
}

.lesson_page .navbar-inverse .navbar-nav>li>a span:nth-child(1) {
    width: 22px;
    float: left;
}

.lesson_page .navbar-inverse .navbar-nav>li>a span:nth-child(2) {
    padding-left: 15px;
    color: #fff;
    font-weight: normal;
    font-family: circular-regular;
}

.lesson_page .navbar-inverse .navbar-nav>li>a {
    display: flex;
    align-items: center;
}

/* .lesson_page .course_continue {
    width: 40%;
    float: left;
    padding: 10px 0px;
    margin: 0;
} */

.lesson_page .last_activity p {
    color: #000;
    /* padding-bottom: 40px; */
    line-height: 24px;
    /* border-bottom: 1px solid #ddd;
    margin-bottom: 15px; */
}

.lesson_page i {
    /* color: rgb(237, 50, 58); */
    font-size: 14px;
    position: relative;
    top: 2px;
}

.watch_video {
    border-radius: 0px;
    padding: 0;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% ;
    border-radius: 10px;
    position: relative;
    margin: 20px 25px;
}
.course_video{
    display: flex;
    align-items: center;

}
.video_progress {
    width: 100px;
    height: 10px;
    border-radius: 18px;
    float: right;
}

.progress-bar {
    background-color: #008000c9;
    box-shadow: initial;
}

.watch_video a {
    width: 50px;
    display: block;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    z-index: 99;
}

.watch_video:after {
    content: '';
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: #00000052;
    /* z-index: 99; */
    position: absolute;
}

.watch_course {
    padding: 30px 40px 30px 50px;
}

.lesson_page .last_activity h6,
.lesson_page .last_activity h5 {
    margin-bottom: 15px;
    color: #000;
    text-transform: capitalize;
}

.pr_out {
    width:100%;
    float: left;
    margin-top: 0px;
}

.pr_out>span {
    font-family: circular-regular;
    font-size: 13px;
    margin-bottom: 5px;
    display: block;
}

.progress-bar {
    position: relative;
    height: 12px;
    width: 100%;
    border-radius: 50px;
    background: #d2d2d2;
}

.filter {
    background: #00D607;
    height: 100%;
    border-radius: 25px;
    transition: all 0.2s ease-in;
}

.resumeSection {
    position: relative;
    /* right: 320px;
      top: 34px;
      */
    width: 200px !important;
   
}

.up_section {
    padding: 25px 0px;
    font-family: circular-regular;
    width: 75%;
    margin: 0 auto;
}

.up_section h1 {
    margin: 0px;
    font-family: circular-bold;
    text-transform: uppercase;
    font-size: 18px;
}

.accordion__button {
    background-color: #fff !important;
    border: 0px !important;
    font-family: circular-bold;
}

.accordion__button:before {
    right: 0;
    position: absolute;
}

.ta_set p {
    margin: 0px;
}

.accordion {
    border: 0px !important;
}
#videolist_right_bar .accordion{
    height: 90vh;
    overflow: auto;
    padding-right: 10px;
}
#videolist_right_bar .accordion__button:before{
    margin-right: 31px;
}
#videolist_right_bar .accordion::-webkit-scrollbar {
    width: 10px;
  }
  #videolist_right_bar .accordion::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  #videolist_right_bar .accordion::-webkit-scrollbar-thumb {
    background: #888; 
  }
  #videolist_right_bar .accordion::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
nav.sidebar .container-fluid {
    height: 100vh !important;
}

.show_section {
    background: #fff;
    padding: 12px 0px;
}
.sidebar_out{
    width: 240px !important;
}

.sh_content {
    float: right;
    padding: 0px;
    background-color: #e9f1fe;
    width: calc(100vw - 240px) !important;
    min-height: 100vh;
}
.sh_content >.video_page_con{
    padding-left: 15px;
}
.show_section p {
    font-family: circular-bold;
    font-weight: bold;
    color: #000;
    width: 75%;
    margin: 0 auto;
}

.whole_lesson {
    float: left;
    width: 100%;
    position: relative;
}

.lesson_page .navbar-brand {
    margin: 0 !important;
    padding: 15px 40px;
}

.lesson_page .navbar-inverse .navbar-nav>li>a {
    font-size: 14px;
    letter-spacing: 0px;
    padding: 15px 40px;
    font-family: circular-regular;
}

nav.sidebar.navbar {
    border-radius: 0px;
}

nav.sidebar,
.main {
    -webkit-transition: margin 200ms ease-out;
    -moz-transition: margin 200ms ease-out;
    -o-transition: margin 200ms ease-out;
    transition: margin 200ms ease-out;
}


/* Add gap to nav and right windows.*/

.main {
    padding: 10px 10px 0 10px;
}


/* .....NavBar: Icon only with coloring/layout.....*/


/*small/medium side display*/

@media (min-width: 768px) {
    /*Allow main to be next to Nav*/
    .main {
        position: absolute;
        width: calc(100% - 40px);
        /*keeps 100% minus nav size*/
        margin-left: 40px;
        float: right;
    }
    /*lets nav bar to be showed on mouseover*/
    /* nav.sidebar:hover+.main {
        margin-left: 200px;
    } */
    /*Center Brand*/
    nav.sidebar.navbar.sidebar>.container .navbar-brand,
    .navbar>.container-fluid .navbar-brand {
        margin-left: 0px;
    }
    /*Center Brand*/
    nav.sidebar .navbar-brand,
    nav.sidebar .navbar-header {
        text-align: center;
        width: 100%;
        margin-left: 0px;
    }
    /*Center Icons*/
    nav.sidebar a {
        padding-right: 13px;
    }
    /*adds border top to first nav box */
    nav.sidebar .navbar-nav>li:first-child {
        border-top: 1px #e5e5e5 solid;
    }
    /*adds border to bottom nav boxes*/
    nav.sidebar .navbar-nav>li {
        border-bottom: none;
    }
    /* Colors/style dropdown box*/
    nav.sidebar .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    /*allows nav box to use 100% width*/
    nav.sidebar .navbar-collapse,
    nav.sidebar .container-fluid {
        padding: 0 0px 0 0px;
        height: 100%;
        background: #fff;
    }
    /*colors dropdown box text */
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: #777;
    }
    /*gives sidebar width/height*/
    nav.sidebar {
        width: 100%;
        height: 100%;
        /* margin-left: -160px; */
        float: left;
        z-index: 8000;
        margin-bottom: 0px;
    }
    /*give sidebar 100% width;*/
    nav.sidebar li {
        width: 100%;
    }
    /* Move nav to full on mouse over*/
    nav.sidebar:hover {
        margin-left: 0px;
    }
    /*for hiden things when navbar hidden*/
    .forAnimate {
        opacity: 0;
    }
}


/* .....NavBar: Fully showing nav bar..... */

@media (min-width: 1330px) {
    /*Allow main to be next to Nav*/
    .main {
        width: calc(100% - 300px);
        margin-left: 300px;
    }
    /*Show all nav*/
    nav.sidebar {
        margin-left: 0px;
        float: left;
    }
    /*Show hidden items on nav*/
    nav.sidebar .forAnimate {
        opacity: 1;
    }
}

nav.sidebar .navbar-nav .open .dropdown-menu>li>a:hover,
nav.sidebar .navbar-nav .open .dropdown-menu>li>a:focus {
    color: #CCC;
    background-color: transparent;
}

nav:hover .forAnimate {
    opacity: 1;
}

/* section {
    padding-left: 15px;
} */


/*Lesson*/


/*PayMent*/

.netbanking.payment_mode {
    float: left;
    width: 100%;
    padding-bottom: 30px;
    text-align: center;
}

.netbanking.payment_mode .form-group label {
    margin-bottom: 10px;
    margin-top: 10px;
}

.netbaning_type a span:nth-child(1) {
    float: left;
    width: 30px;
    padding: 5px;
}

.upi .form-group {
    max-width: 80%;
    margin: 0 auto;
    float: initial;
    display: inline-block;
}

.netbaning_type a span:nth-child(2) {
    float: left;
    padding: 7px 0px;
    width: 99px;
}

.wallets.payment_mode,
.upi {
    text-align: center;
}

.netbaning_type_out {
    max-width: 83%;
    text-align: center;
    margin: 0 auto;
    float: initial;
    display: inline-block;
}

.netbaning_type_out .netbaning_type:nth-child(even) {
    padding-left: 0px;
}

.netbaning_type a {
    color: #666;
    float: left;
    width: 100%;
    border: 1px solid #666;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 5px 10px;
}

.netbanking.payment_mode .form-group {
    max-width: 84%;
    margin: 0 auto;
    float: initial;
    display: inline-block;
    text-align: left;
}

.payment input {
    border-radius: 5px;
    border-color: #666;
    margin-bottom: 20px;
    color: #666;
}

.payment .sign_up {
    width: 150px;
    margin-bottom: 30px;
    /* padding: 10px 0px; */
    height: 40px;
    padding: 0;
    margin-top: 20px;
    float: initial;
    display: inline-block;
    /* width: 30%; */
    font-weight: initial;
    text-transform: uppercase;
    font-size: 14px;
}

.payment .gate_way {
    margin: 120px 0px;
}

.plan_setails_out {
    background: #fff;
    text-align: center;
    float: right;
    padding: 30px;
    border: 1px solid #19a7f8;
}

.plan_setails_out p {
    float: left;
    text-align: left;
    width: 100%;
}

.amount_set {
    border-top: 1px solid #6666665c;
    border-bottom: 1px solid #6666665c;
    padding: 20px 0px;
}

.plan_setails_out p span:nth-child(2) {
    float: right;
}

.product {
    margin: 10px 0px 15px 0px;
    display: block;
    float: left;
    text-align: left;
    width: 70%;
    color: #009ef9 !important;
    font-family: circular-bold;
    text-decoration: none !important;
}

.tit_setting span {
    font-family: circular-bold;
}

.total_price {
    margin: 10px 0px;
}

.total_price span {
    font-family: circular-bold;
}

.subscription {
    border-radius: 30px;
    /* text-align: center; */
    margin: 20px 0px 0px 0px;
    background: rgb(158, 158, 158);
}

.subscription:hover {
    background: #009ef9;
    color: #fff;
    border-color: #009ef9;
    transition: all 0.5s ease-in-out;
}

.describtion {
    float: left;
    width: 100%;
    border-bottom: 1px solid #009EF9;
}

.total_price span:nth-cild(2) {
    font-family: circular-regular !important;
    color: #000;
}

.privacy {
    font-size: 10px;
}

.pr_details {
    color: #666;
    margin: 30px 0px 30px 0px;
    line-height: 24px;
    font-family: circular-regular;
}

.amount_set .col-lg-7 {
    float: right;
}

.payment_form {
    border: 1px solid #009EF9;
    max-width: 53%;
    padding: 0px;
    background: #fff;
    border-bottom: 0px;
}

.terms_set {
    padding-left: 45px;
}

.terms_set span {
    left: 15px;
}

.continue_set {
    text-align: center;
}

.payment_type.open span {
    background: #009ef9;
}

.custom_radio {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select_payment form {
    padding: 20px 20px !important;
}


/* Hide the browser's default radio button */

.container .custom_radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}


/* Create a custom radio button */

.radio_checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #fff !important;
    border-radius: 50%;
    border: 1px solid #6666;
}

.exp_set {
    max-width: 48%;
}

.exp_monthset {
    float: right !important;
}

.payment label {
    float: left;
    width: 100%;
    cursor: pointer;
    color: #515151;
    margin-bottom: 10px;
}


/* On mouse-over, add a grey background color */

.container:hover input~.radio_checkmark {
    background-color: #ccc;
}


/* When the radio button is checked, add a blue background */

.container input:checked~.radio_checkmark {
    background-color: #2196F3;
}


/* Create the indicator (the dot/circle - hidden when not checked) */

.radio_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the indicator (dot/circle) when checked */

.container input:checked~.radio_checkmark:after {
    display: block;
}


/* Style the indicator (dot/circle) */

.container .radio_checkmark:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #009ef9;
}

.payment_type span {
    margin-right: 20px;
    background: rgb(158, 158, 158);
    color: #fff;
    padding: 3px;
    border-radius: 100%;
    width: 25px;
    display: inline-block;
    font-size: 14px;
    height: 25px;
    text-align: center;
}

.payment_mode {
    display: none;
}

label.custom_radio {
    border-bottom: 1px solid #cccccc7d;
    padding-bottom: 6px;
    margin-bottom: 15px;
}

::placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
}

.card_number {
    position: relative;
    padding-right: 0px;
}

.cvv_set .place_set {
    right: 6%;
}

.place_set {
    position: absolute;
    top: 41%;
    left: auto;
    color: #666;
    right: 3%;
    font-size: 18px;
}

.payment_type.open:after {
    display: none;
}

.payment_type:after {
    content: '';
    border-bottom: 1px solid #18a7f9;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.describtion {
    transition: all 0.5s ease-in;
    display: none;
}

.describtion.open {
    display: block;
    transition: all 0.5s ease-in;
}

.payment_type.open {
    background: #fff;
}

.payment_type {
    position: relative;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
    display: block;
    padding: 20px;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none !important;
    color: #000 !important;
    /* margin-left: unset; */
    /* border: 0px; */
    font-weight: bold;
    font-family: circular-regular;
}

.payment .form-control {
    height: 45px;
    float: left;
    border-radius: 5px;
    font-family: circular-bold;
    color: #666;
}

.payment form {
    box-shadow: initial;
    background: #fff;
    float: left;
    width: 100%;
    padding: 10px;
}

@media only screen and (max-width:993px) {
    .netbaning_type_out .netbaning_type {
        width: 100%;
    }
    .netbaning_type_out .netbaning_type:nth-child(even) {
        padding-left: 15px;
    }
    .course_video{
        flex-wrap: wrap;
    }
    .watch_video{
        width: 100%;
    }
}

@media only screen and (max-width:767px) {
    .payment_form {
        max-width: 100%;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width:560px) {
    .netbaning_type_out .netbaning_type {
        width: 100%;
    }
    .netbaning_type_out .netbaning_type:nth-child(even) {
        padding-left: 15px;
    }
}

@media only screen and (max-width:481px) {
    .set_drop {
        width: 100%;
        margin-bottom: 20px !important;
    }
    .postal_code {
        width: 100%;
    }
    .amount_set .col-lg-7 {
        width: 100%;
    }
    .payment .gate_way {
        padding: 0;
    }
}


/*PayMent*/


/*signup*/

.or_set {
    text-align: center;
    width: 20%;
    display: inline-block;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.lessons_only {
    position: relative;
    width: 100%;
    z-index: 99;
    top: 0;
    background: #3C3C3C;
}

/* .cousre_videos.container {
    margin-top: 200px;
} */

.sign_uppage .container-fluid {
    background: #fff;
    float: left;
    width: 100%;
}

.sign_uppage.sign_inpage .container.auto_width {
    max-width: 1020px;
}

.sign_uppage .sigup_set {
    background: rgb(243, 243, 243);
}

.sign_uppage .container {
    width: auto;
    max-width: 1020px;
}

.sign_uppage .navbar-default {
    background: #fff;
}

.sigup_set {
    padding: 120px 0px 0px;
}

.content a {
    background-color: #ffc300;
    width: 170px;
    height: 38px;
    display: block;
    margin: 0 auto;
    text-decoration: none;
    text-align: center;
    border-radius: 4px;
    line-height: 41px;
    color: #fff;
    font-size: 19pxx;
    font-size: 16px;
}

.signup input {
    border-radius: 5px;
    background-color: #ebf1ff;
    margin: 7px 0px;
}

.content-link {
    font-size: 12px;
    letter-spacing: .3px;
    margin-left: 10px;
    margin-bottom: 15px;
    float: left;
    margin-left: 0;
}

.foget_pwd {
    text-align: right;
}

.form-control {
    height: 35px;
}

.signup {
    width: 100%;
    background: #Fff;
    padding: 30px 38px;
    float: left;
}

.signup h2 {
    color: #000;
    font-family: circular-bold;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 25px;
}

.ent_set {
    margin-bottom: 50px;
}

.name {
    padding: 0;
}

.name input {
    width: 90%;
}

.lastname label {
    margin-left: 25px;
}

.lastname input {
    float: right;
}

.rightline {
    width: 40%;
    float: right;
}

.social-link {
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 10px 9px;
    width: 48%;
}

.flt-right {
    float: right;
}

.social-link p {
    margin-bottom: 0;
    font-size: 17px;
}

.social-link img {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.accnt-signup p {
    display: inline-block;
    margin: 30px 0 30px;
}

.bg-box h5 {
    margin: 10px 0;
    font-family: circular-regular;
}

.bg-box h5 strong {
    font-size: 30px;
    font-weight: normal;
}

.bg-box h4 {
    font-size: 16px;
    margin: 10px 0px 0px 0px;
}

.bg-box p {
    font-size: 16px;
    margin-bottom: 22px;
    color: #666;
}

button {
    background-color: #2c86fe;
}

.leftline {
    width: 40%;
    float: left;
}

hr {
    border-top: 1px solid #707070;
}

.bg-box {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    padding: 20px 40px;
    margin-left: 0px;
    border-radius: 5px;
    background: #fff;
}

.bg-box-content1 {
    margin: 30px 0px 50px 0px;
}

.signup label {
    font-family: circular-regular;
    font-size: 16px;
    color: #666;
    font-weight: 700;
    margin-bottom: 0;
}

/* form {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 20px;
} */

.popup-box .form-horizontal .form-group {
    margin: 22px 0px !important;
}

.remember_out label {
    padding-left: 33px;
    color: #009ef9;
    font-size: 14px;
    cursor: pointer;
    text-transform: capitalize;
    font-weight: initial;
    letter-spacing: 0.5px;
}

.foget_pwd a {
    text-decoration: none;
    color: #009ef9;
}

.remember_out {
    margin-bottom: 10px;
}


/* Hide the browser's default checkbox */

.container .remember_me {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 5px;
    background-color: #eee;
}


/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container input:checked~.checkmark {
    background-color: #2196F3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 8px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.form-group {
    margin-bottom: 0;
}

.margin_none {
    margin: 0px;
}

.sign_up {
    display: block;
    background: #009EF9 !important;
    width: 150px;
    border: 0px !important;
    height: 40px;
    border-radius: 35px;
    color: #ffffff !important;
    font-size: 16px;
    font-weight: bold;
    margin: 0 auto;
}

.social-link a {
    color: #000;
    text-decoration: none;
}


@media only screen and (max-width:1024px) {

.be_content_set h1 {
    font-size: 38px;
    margin-top: 160px;
}

.box {
    position: relative;
    width: 90%;
}

.close-icon {
    right: calc(7.5% - 30px);
}

}

@media only screen and (max-width:425px) {

    .be_content_set h1 {
        font-size: 26px;
        margin-top: 175px;
        margin-bottom: 10px;
    }
    
    .be_content_set p {
        font-size: 14px !important;
        margin-bottom: 15px;
    }

    .phone h5,
    .email h5,
    .address h5 {
        margin-left: 30px !important;
    }
    .company {
        margin-bottom: 30px !important;
        margin-top: 30px;
    }

    .navbar-default .navbar-nav>li>a {
        padding: 10px 13px !important;
    }

}    

@media only screen and (max-width:1200px) {
    .signup {
        padding: 30px 40px;
    }
    .social-link img {
        width: 25px;
        height: 25px;
    }
    .social-link p {
        font-size: 14px;
    }
}

@media only screen and (max-width:993px) {
    .bg-box {
        width: 46%;
        float: left;
        margin: 55px 0px 50px 0px;
        padding: 40px;
    }
    .bg-box-content1 {
        margin: 55px 54px 50px 0px;
    }
    .phone h5,
    .email h5,
    .address h5 {
        margin-left: 30px !important;
    }
}

@media only screen and (max-width:767px) {
    .bg-box {
        width: 45%;
        margin: 40px 0px 0px 0px;
    }
    .signup {
        padding: 30px 20px;
    }
    .social-link img {
        width: 15px;
        height: 15px;
    }
    .social-link a {
        font-size: 12px;
    }
}

@media only screen and (max-width:660px) {
    .bg-box {
        width: 100%;
    }
}


@media only screen and (max-width:481px) {

    .sm_setting
    {
        max-width: 95%;
        margin: 10px 20px !important;
        float: none !important;
    }

    .sgin_form {
        padding: 0px;
    }
    .name input {
        width: 100%;
    }
    .lastname label {
        margin-left: 0px;
    }
    .form-control {
        height: 45px;
    }
    .signup label {
        font-size: 14px;
    }
    .signup h2 {
        font-size: 24px;
    }
    .sign_up {
        width: 150px;
        height: 45px;
        font-size: 16px;
    }
    .or_set {
        font-size: 22px;
    }
    .social-link {
        width: 100%;
        margin-bottom: 15px !important;
    }
    .content a {
        width: 150px;
    }
    .ad_content {
        padding: 0px;
    }
    .name {
        width: 100%;
    }
    .remember_out .col-xs-6 {
        width: 100%;
        text-align: left;
        margin: 10px 0px;
    }
    .remember_out {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:1349px) {
    ul.nav.navbar-nav.navbar-right {
        width: auto;
        padding-right: 35px;
    }
}
@media only screen and (max-width:1024px) 
{
    a.navbar-brand {
        width: 60%;
        margin-left: 0px !important;
    }
    .navbar-default .navbar-nav>li>a {
        font-size: 16px;
        padding: 21px 18px;
    }
    .navbar-header {
        width: 23%;
    }
}

@media only screen and (max-width:993px) 
{   
    .navbar-brand {
        padding: 15px 0px;
    }    
    a.navbar-brand {
        width: 100% !important;
        margin-left: 0px !important;
    }
    .navbar-default .navbar-nav>li>a {
        font-size: 15px;
        padding: 21px 18px;
    }
    .navbar-header {
        width: 15%;
        margin-right: 30px !important;
    }
}

@media only screen and (max-width:425px)
{
    a.navbar-brand {
        width: 30% !important;
        margin-left: 0px !important;
    }
}


@media only screen and (max-width:1200px) {
    /* .slick-prev {
        left: -10px;
        z-index: 99;
    } */
    /* .slick-next {
        right: -10px;
    } */
    #degree_exp .row {
        margin: 0px;
    }
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse {
    margin-right: 0px;
    margin-left: 0px;
}

@media only screen and (max-width:993px) {
    .navbar-default .navbar-nav>li>a {
        padding: 21px 13px;
    }
    /* #all_ourprogram .slick-slide {
        margin: 15px;
        padding: 15px;
    } */
    .content_set h1 {
        line-height: 24px;
    }
    .nav-tabs li a {
        font-size: 14px;
    }
    .margin_set {
        width: 100%;
        height: 100%;
    }
    .digi_mrk h1 {
        font-size: 24px;
    }
    .sm_box {
        max-width: 350px;
    }
    footer {
        padding: 30px 20px;
    }
    a.navbar-brand {
        width: 75%;
    }
    .syllabus {
        margin-left: 100px;
    }
    .entroll {
        margin-right: 100px;
    }
    .social_set li {
        width: 23%;
        margin-right: 8px;
    }
    #timeline .timeline-movement .timeline-item .timeline-panel.credits .timeline-panel-ul {
        width: 100%;
    }
    #timeline .off_set_ul {
        margin-left: 50px !important;
    }
    .about_sec p {
        max-width: 100%;
    }
    footer li {
        margin-right: 9px;
    }
    .company {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width:767px) {
    .r_dahboard{
        background-color: #e9f1fe;
    }
    .up_section .col-lg-12 > div >img{
        display: none;
    }
    .last_bottom_nav{
        position: relative !important;
        bottom: 0 !important;
    }
    .sidebar_out{
        height: 100px;
        width: 100%;
        z-index: 1000;
    }
    nav.sidebar .container-fluid{
        height: 100px !important;
    }
    .navbar{
        opacity: 1;
        height: 100px;
    }
    .navbar-inverse{
        background-color: #222222c4;
    }
    .navbar-nav{
        position: absolute;
        z-index: 999;
        background: #fff;
        width: 100%;      
      }
      .sidebar_out{
        width: 100% !important;
    }
    .sh_content{
        float: none;
        position: relative;
        z-index: 0;
        margin-top: 100px;
        width: 100% !important;
    }
    .navbar-brand > img{
        height: 55px;
    }
    .lesson_page .navbar-brand{
        padding: 23px 5px;
        width: 200px !important;
    }
    .navbar-header {
        width: 100%;
    }
    .nav-tabs li a {
        padding: 15px 7px;
        font-size: 12px;
    }
    #benefits p {
        font-size: 14px !important;
    }
    #certification .row {
        margin: 0px;
    }
    .content_certi li:before {
        left: -40px;
    }
    .content_certi ul {
        float: left
    }
    .gr_set {
        padding: 0px;
    }
    #timeline .timeline-badge,
    #timeline .timeline-badge-two,
    #timeline:before {
        display: none;
    }
    /* .slick-arrow {
        display: none !important;
    } */
    .set_wide .col-xs-6 {
        margin-bottom: 30px;
    }
    #tools .set_wide:nth-child(2) {
        margin-bottom: 0;
    }
    .top_rating p {
        font-size: 18px;
    }
    .top_rating .testimonial_set {
        padding: 40px 20px;
    }
    .company {
        margin-bottom: 40px;
    }
    #timeline .off_set_ul {
        margin-left: 0px !important;
    }
    .off_set {
        margin-top: 15px;
    }
    footer {
        padding: 30px 15px;
    }
    footer .row {
        margin: 0px;
    }
    /* #all_ourprogram .slick-slide {
        margin: 10px;
    } */
    .whole_slide {
        padding: 0px;
    }
    a.navbar-brand {
        width: 30%;
        margin-left: 15px !important;
    }
    .navbar-toggle {
        margin: 33px 0px;
    }
    .banner_content h1 {
        font-size: 28px;
    }
    .syllabus,
    .entroll {
        margin: 0px;
    }
    .whole_deg {
        padding: 40px 25px;
    }
    .digi_mrk h1 {
        font-size: 18px;
    }
    .banner_content {
        padding: 150px 0px;
    }
    .collabration_set h1 {
        font-size: 24px;
    }
    .social_set li {
        width: 46%;
    }
    .social_set li:after {
        display: none;
    }
    .certi_set {
        margin: 0 auto;
    }
    .sm_box button {
        width: 100%;
        margin-top: 10px;
    }
    .sm_box {
        max-width: 48%;
    }
    .sign_in {
        margin-left: 15px;
    }
    .navbar-default .navbar-collapse {
        z-index: 9;
        width: 100%;
        background: #fff;
        max-height: initial;
    }
    div#banner_bottom:before {
        display: none;
    }
    .pre_desc p:nth-child(3) {
        font-size: 12px;
    }
    .time_res {
        max-width: 300px;
        height: 282px;
        margin: 0 auto;
        float: initial;
        display: inline-block;
        margin-bottom: 30px;
    }
    .margin_set {
        padding: 66px 1px;
    }
    a.navbar-brand {
        width: 150px;
    }
    footer li {
        margin-right: 7px;
    }
    footer .container {
        padding: 0px;
    }
    .about_sec p {
        padding: 0;
    }
}

@media only screen and (max-width:481px) {
    .banner_content p,
    .banner_content h1,
    .timeline-panel-ul h2,
    .trv_desc>div h5 {
        font-size: 14px;
    }
    .set_small {
        width: 100%;
    }
    .nav-tabs>li {
        display: inline-block;
        float: initial;
    }
    .discover h5 {
        margin-top: 40px;
    }
    .time_set {
        padding-right: 0;
        padding-left: 0;
    }
    .time_res {
        padding: 0px;
    }
    .banner_content {
        padding: 100px 0px;
    }
    .res_setting {
        width: 100%;
        padding: 0px;
    }
    footer .col-xs-6 {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .sm_box {
        max-width: 100%;
        width: 100%;
        margin-top: 10px;
    }
    .down_set {
        width: 15px;
        float: left;
        margin-right: 6px;
    }
    .page-header {
        margin: 0px;
    }
    .syllabus,
    .entroll {
        margin: 10px auto;
        float: initial;
    }
    .social_set li {
        width: 100%;
        margin: 0;
    }
}


.course_list{
    padding: 40px;
}
/* .course_list  div{
    float: none;
} */
.course_list_top.white_bg{
    background-color: #fff;
    color: #000;
    box-shadow: 9px 2px 4px rgb(0 0 0 / 18%);
}
.course_list_top.white_bg svg{
    max-width: 28px;
}
.white_bg .lat_activity{
    color: #000 !important;
    height: 100px;
    display: flex;
    align-items: center;
}
.course_list_top h1{
    font-size: 23px;
}
.course_list_top p{
    margin: 0;
}
.course_list_top .up_section{
    margin: 0 6%;
}
/* .user_sidebar{
    background-color: red;
} */
.user_sidebar{
    background-color: rgb(12, 37, 65) ;
}
.user_sidebar svg path,.last_bottom_nav:hover svg path{
    fill:#fff;
}
.user_active_sidebar svg path,#user_sidebar:hover svg path,.last_bottom_nav:hover svg path{
    fill:rgb(12, 37, 65) ;
}
.user_active_sidebar,#user_sidebar:hover,.last_bottom_nav:hover{
    background-color: #e9f1fe !important;
}
.user_active_sidebar span,#user_sidebar:hover span,.last_bottom_nav:hover span{    
    color: rgb(12, 37, 65) !important;
}

/* COURSE LIST */
.user_course_list .bottom_info{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

}
.user_course_list h5{
    text-transform: uppercase;
}
.user_course_list .bottom_info p{
    height: fit-content;
    margin: 0;
    color: grey;
}